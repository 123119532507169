import { Icon } from "@mui/material";
import styled from "@mui/material/styles/styled";
import { PaletteProps, palette } from "@mui/system";

export const CircledIcon = styled(Icon)<PaletteProps>((props) => ({
  padding: props.theme.spacing(4),
  boxSizing: "content-box",
  borderRadius: "100%",
  display: "flex",
  ...palette({ bgcolor: "primary.light", ...props }),
}));
