import { useCallback, useState } from "react";

export function useLocalStorage<T>(
  key: string
): [T | undefined, (value: T) => void, () => void];

export function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, (value: T) => void, () => void];

export function useLocalStorage<T>(
  key: string,
  initialValue?: T
): [T | undefined, (value: T) => void, () => void] {
  const [storedValue, setStoredValue] = useState<T | undefined>(() => {
    const item = window.localStorage.getItem(key);
    try {
      return item ? JSON.parse(item) : initialValue;
    } catch {
      window.localStorage.removeItem(key);
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T) => {
      setStoredValue(value);
      window.localStorage.setItem(key, JSON.stringify(value));
    },
    [key]
  );

  const removeValue = useCallback(() => {
    setStoredValue(undefined);
    window.localStorage.removeItem(key);
  }, [key]);

  return [storedValue, setValue, removeValue];
}
