import React from "react";

import { RouterProvider } from "react-router-dom";

import { AuthProvider } from "./contexts/AuthContext";
import MaintenancePage from "./pages/maintenance/maitenancePage";
import { router } from "./routes";
import { checkMaintenanceMode } from "./utils/maintenance";

const App = () => {
  const { isMaintenaceMode, endOfMaintenance } = checkMaintenanceMode();

  if (isMaintenaceMode) {
    return <MaintenancePage endOfMaintenance={endOfMaintenance} />;
  }

  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
};

export default App;
