import { LoginResponse } from "~models/LoginResponse";
import axios from "~utils/axios";

export type LogIn =
  | { loginUrl: string; matriculation: string }
  | { email: string; matriculation: string; password: string };

function logIn(args: LogIn): Promise<LoginResponse> {
  return axios.post("/api/v1/login", args).then((response) => ({
    ...response.data,
    authorization: response.headers.authorization,
  }));
}

export const authService = {
  logIn,
};
