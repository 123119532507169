import React, { FC, useCallback, useEffect, useState } from "react";

import {
  Backdrop,
  Box,
  CircularProgress,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { useMuiForm } from "~hooks/useMuiForm";
import { CompanyInfo as Company } from "~models/CompanyInfo";
import { CompanyInfoSchema } from "~models/Schemas";
import { RecapStep, RecapStepProps } from "~pages/esign/recap/index";
import { AsyncResult, toAsyncResult } from "~services/asyncResult";
import { esignService } from "~services/esignService";

const CompanyInfoFC: FC<RecapStepProps> = ({
  handleBack,
  signatureSummary,
}) => {
  const { register, getValues, setValue } = useMuiForm("companyInfo");
  const [companyInfo, setCompanyInfo] = useState<AsyncResult>({
    type: "loading",
  });
  const siren = getValues("legalPersonInformation.siret")
    .replaceAll(" ", "")
    .slice(0, 9);
  const readOnly = !!signatureSummary.renterSummary;
  const inputProps = useCallback(
    (label: string) => {
      return {
        ...register(label),
        InputProps: { readOnly },
      };
    },
    [readOnly, register]
  );
  const onBack = () => {
    setValue("companyInfo", undefined);
    handleBack();
  };
  useEffect(() => {
    const fillWith = (companyInfo: Company) =>
      Object.entries(companyInfo).forEach(([key, value]) => {
        value && setValue(`companyInfo.${key}`, value);
      });
    if (!signatureSummary.renterSummary) {
      toAsyncResult(esignService.getCompanyInfo(siren).then(fillWith)).then(
        setCompanyInfo
      );
    } else {
      fillWith(signatureSummary.renterSummary.companyInfoRequest);
      setCompanyInfo({ type: "success", content: {} });
    }
  }, [setValue, signatureSummary.renterSummary, siren]);

  return (
    <Stack spacing={4} width="100%">
      <Backdrop
        open={companyInfo.type === "loading"}
        sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}
      >
        <CircularProgress />
      </Backdrop>
      {!readOnly && companyInfo.type === "success" && (
        <Link onClick={onBack} color="primary" textAlign="center">
          <Typography variant="subtitle2">
            Ce n'est pas votre entreprise? Cliquez ici pour modifier votre Siret
          </Typography>
        </Link>
      )}
      {companyInfo.type === "failed" && <FailedComponent onBack={onBack} />}
      <TextField label="Forme juridique" {...inputProps("legalStatus")} />
      <TextField label="Nom de la société" {...inputProps("companyName")} />
      <TextField
        type="number"
        label="Capital social"
        {...inputProps("shareCapital")}
      />
      <Typography fontWeight="bold" my={2}>
        Adresse du siège
      </Typography>
      <TextField label="Voie" {...inputProps("headOfficeStreet")} />
      <TextField label="Code postal" {...inputProps("headOfficePostalCode")} />
      <TextField label="Commune" {...inputProps("headOfficeCity")} />
      <TextField label="Pays" {...inputProps("headOfficeCountry")} />
    </Stack>
  );
};

const FailedComponent: FC<{ onBack: () => void }> = ({ onBack }) => (
  <Box textAlign="center">
    <Typography color="error.main" textAlign="center">
      SIRET introuvable dans le Registre National des Entretprises (RNE).
    </Typography>
    <Link onClick={onBack} color="primary">
      <Typography variant="subtitle2">
        Cliquez ici pour modifier le SIRET
      </Typography>
    </Link>
    <Typography variant="subtitle2">
      ou renseignez manuellement le formulaire ci-dessous :
    </Typography>
  </Box>
);

export const CompanyInfo: RecapStep = Object.assign(CompanyInfoFC, {
  title: "Informations complémentaires",
  schema: CompanyInfoSchema,
});
