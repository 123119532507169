import {
  checkboxClasses,
  radioClasses,
  typographyClasses,
} from "@mui/material";
import { Components } from "@mui/material/styles/components";
import { Theme } from "@mui/material/styles/createTheme";
import type {} from "@mui/material/themeCssVarsAugmentation";

const components: Components<Omit<Theme, "components">> = {
  MuiAppBar: {
    styleOverrides: {
      colorPrimary: {
        borderRadius: 0,
        color: "#000000",
        backgroundColor: "#FFFFFF",
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButton: {
    defaultProps: {
      size: "large",
    },
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: 100,
        padding: theme.spacing(4, 6),
        fontWeight: 500,
        minWidth: "8em",
      }),
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: "h6",
        fontWeight: "bold",
      },
    },
  },
  MuiCard: {
    defaultProps: {
      variant: "outlined",
    },
    styleOverrides: {
      root: ({ theme }) => ({
        width: "100%",
        backgroundImage: "none",
      }),
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        ":last-child": {
          paddingBottom: theme.spacing(4),
        },
      }),
    },
  },
  MuiContainer: {
    defaultProps: {
      maxWidth: "sm",
    },
    styleOverrides: {
      root: ({ theme }) => ({
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      }),
    },
  },
  MuiDialog: {
    defaultProps: {
      fullWidth: true,
    },
    styleOverrides: {
      container: ({ ownerState }) =>
        ownerState.fullScreen ? { alignItems: "flex-end" } : {},
      paper: ({ ownerState }) =>
        ownerState.fullScreen ? {} : { borderRadius: 16 },
      paperFullScreen: {
        height: "auto",
        maxHeight: "100%",
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        justifyContent: "center",
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        textAlign: "center",
        fontWeight: "bold",
      },
    },
  },
  MuiLink: {
    defaultProps: {
      color: "inherit",
    },
    styleOverrides: {
      root: {
        cursor: "pointer",
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.spacing(2),
        backgroundColor: theme.vars.palette.background.paper,
      }),
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.spacing(2),
      }),
    },
    variants: [
      {
        props: {
          variant: "soft",
        },
        style: ({ theme }) => ({
          backgroundColor: theme.vars.palette.primary.light,
        }),
      },
    ],
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
      },
    },
  },
  MuiStack: {
    defaultProps: {
      spacing: 6,
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        alignItems: "baseline",
        [`.${checkboxClasses.root} + .${typographyClasses.root}`]: {
          transform: "translateY(-0.35em)",
        },
        [`.${radioClasses.root} + .${typographyClasses.root}`]: {
          transform: "translateY(-0.25em)",
        },
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      fullWidth: true,
      InputLabelProps: {
        shrink: true,
      },
    },
  },
};

export default components;

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    soft: true;
  }
}
