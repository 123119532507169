import React, { FC, useCallback, useMemo, useState } from "react";

import { FormProvider, useForm } from "react-hook-form";

import { Card, CardContent, Link, Stack, Typography } from "@mui/material";

import { yupResolver } from "@hookform/resolvers/yup";
import { ObjectSchema } from "yup";
import { ButtonGroup } from "~components/ButtonGroup";
import { CircledIcon } from "~components/CircledIcon";
import { NextButton } from "~components/NextButton";
import { Template } from "~components/Template";
import { useMediaDesktop } from "~hooks/useMediaDesktop";
import { SignatureLink } from "~models/SignatureLink";
import { StepStatus } from "~models/StepStatus";
import { UserChoiceOptions } from "~models/UserChoiceOptions";
import { Error } from "~pages/Error";
import { Loading } from "~pages/Loading";
import { StepProps } from "~pages/esign";
import { CoholdersInfo } from "~pages/esign/recap/CoholdersInfo";
import { CompanyInfo } from "~pages/esign/recap/CompanyInfo";
import {
  KeepConditions,
  RepairConditions,
  SellConditions,
} from "~pages/esign/recap/Conditions";
import { LegalPersonInfo } from "~pages/esign/recap/LegalPersonInfo";
import { RenteeInfo } from "~pages/esign/recap/RenteeInfo";
import { RepairMandateInfo } from "~pages/esign/recap/RepairMandateInfo";
import { esignService } from "~services/esignService";
import { showCGInstruction } from "~utils/signatureRequest";
import { ChoiceIcon, ChoiceTitle } from "~utils/wording";

export type RecapStepProps = StepProps<"Recap"> & { handleBack: () => void };
export type RecapStep = FC<RecapStepProps> & {
  title: string;
  schema?: ObjectSchema<any>;
};

export const Recap: FC<StepProps<"Recap">> = (props) => {
  const desktop = useMediaDesktop();
  const { step, setStep, signatureSummary } = props;
  const {
    signatureData,
    repairMandate,
    coholdersInformation,
    applicationData,
  } = signatureSummary;
  const { choice } = step;

  const [sell, repair, keep] = ["sell", "repair", "keep"].map(
    (it) => choice === it
  );

  const { currentUser, hasMultiSigners } = signatureData;
  const { isLegalPerson, isRenter, isHolder } = currentUser;
  const needInfo = isHolder || sell;
  const [signAction, setSignAction] = useState<{
    options: UserChoiceOptions;
    action: Promise<SignatureLink>;
  }>();
  const [signFailed, setSignFailed] = useState(false);
  const [recapStep, setRecapStep] = useState(0);
  const recapSteps: RecapStep[] = [
    ...(sell ? [SellConditions] : []),
    ...(repair ? [RepairConditions] : []),
    ...(keep ? [KeepConditions] : []),
    ...(needInfo && isLegalPerson && !isRenter ? [LegalPersonInfo] : []),
    ...(needInfo && isRenter ? [RenteeInfo] : []),
    ...(isHolder && isLegalPerson ? [CompanyInfo] : []),
    ...(isHolder && sell && hasMultiSigners ? [CoholdersInfo] : []),
    ...(isHolder && repair && repairMandate ? [RepairMandateInfo] : []),
  ];
  const isFirstStep = recapStep === 0;
  const isLastStep = recapStep === recapSteps.length - 1;
  const CurrentStep = recapSteps[recapStep];
  const schema = recapSteps
    .slice(0, recapStep)
    .reduce(
      (schema, step) =>
        schema
          ? step.schema
            ? schema.concat(step.schema)
            : schema
          : step.schema,
      CurrentStep.schema
    );
  const methods = useForm<UserChoiceOptions>({
    shouldUnregister: false,
    resolver: schema && yupResolver(schema),
    context: { currentUser, choice, coholdersInformation },
    defaultValues: {
      renterInformation: signatureSummary.renterSummary?.renterInformation,
    },
  });
  const stepStatus: StepStatus = useMemo(
    () => ({ type: "Recap", choice, step: recapStep }),
    [choice, recapStep]
  );
  const handleNext = methods.handleSubmit((userOptions) => {
    isLastStep
      ? setSignAction({
          options: userOptions,
          action: esignService.createSignature(choice, userOptions),
        })
      : setRecapStep(recapStep + 1);
  });
  const handleBack = () =>
    isFirstStep ? setStep({ value: "Choice" }) : setRecapStep(recapStep - 1);
  const onSignError = useCallback(() => {
    setSignFailed(true);
    setSignAction(undefined);
  }, [setSignFailed]);
  const onSignLoaded = useCallback(
    (options: UserChoiceOptions, link: SignatureLink) =>
      setStep({ value: "Signature", choice, link, options }),
    [setStep, choice]
  );

  if (signFailed) {
    return <Error onBack={() => setSignFailed(false)} />;
  }

  if (signAction) {
    return (
      <Loading
        completedCG={false}
        hasCG={showCGInstruction(signatureSummary)}
        signed={false}
        action={signAction.action}
        onError={onSignError}
        onLoaded={(link) => onSignLoaded(signAction.options, link)}
      />
    );
  }

  return (
    <Template
      back={handleBack}
      title={CurrentStep.title}
      progress={30}
      verticalCentered={desktop}
      stepStatus={stepStatus}
    >
      <Card variant="soft">
        <CardContent>
          <Stack direction="row">
            <CircledIcon>{ChoiceIcon[choice]({})}</CircledIcon>
            <Stack spacing={2}>
              <Typography fontWeight="bold">
                {ChoiceTitle(choice, applicationData.sinisterType)}
              </Typography>
              <Link
                color="primary.dark"
                onClick={() => setStep({ value: "Choice" })}
              >
                Modifier mon choix
              </Link>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <FormProvider {...methods}>
        <CurrentStep {...props} handleBack={handleBack} />
      </FormProvider>
      <ButtonGroup>
        <NextButton onClick={handleNext}>
          {isLastStep ? "Signer mes documents" : "Suivant"}
        </NextButton>
      </ButtonGroup>
    </Template>
  );
};
