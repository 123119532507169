import React, { FC } from "react";

import { Stack, TextField } from "@mui/material";

import { SingleCheckbox } from "~components/SingleCheckbox";
import { MaskedTextField } from "~components/TextMaskInput";
import { useMuiForm } from "~hooks/useMuiForm";
import { LegalPersonInfoSchema } from "~models/Schemas";
import { StepProps } from "~pages/esign";
import { RecapStep } from "~pages/esign/recap/index";

const LegalPersonInfoFC: FC<StepProps<"Recap">> = ({ signatureSummary }) => {
  const { register, watch } = useMuiForm("legalPersonInformation");
  const isHolder = signatureSummary.signatureData.currentUser.isHolder;
  return (
    <Stack spacing={4}>
      <SingleCheckbox
        label={
          <>
            Je confirme être <b>habilité(e) à signer les documents</b> pour le
            compte de ma structure
          </>
        }
        {...register("confirm")}
        checked={!!watch("confirm")}
      />
      <TextField label="Votre prénom" {...register("firstName")} />
      <TextField label="Votre nom" {...register("lastName")} />
      <TextField label="Votre fonction" {...register("position")} />
      {isHolder && (
        <MaskedTextField
          type="tel"
          label="SIRET de la structure"
          mask="000 000 000 00000"
          {...register("siret")}
        />
      )}
    </Stack>
  );
};

export const LegalPersonInfo: RecapStep = Object.assign(LegalPersonInfoFC, {
  title: "Informations complémentaires",
  schema: LegalPersonInfoSchema,
});
