import React, { FC, useEffect, useState } from "react";

import { ArrowBackIosNew } from "@mui/icons-material";
import {
  AppBar,
  Container,
  IconButton,
  LinearProgress,
  Toolbar,
  Typography,
  linearProgressClasses,
} from "@mui/material";

export interface Props {
  back?: () => void;
  progress?: number;
  title: React.ReactNode;
}

// Find a better solution? works because Header is only rendered once.
const useProgress = (<T,>() => {
  let previousValue: T | undefined = undefined;
  return (newValue?: T) => {
    const [currentValue, setProgress] = useState(
      (newValue && previousValue) || newValue
    );
    useEffect(() => {
      previousValue = newValue;
      setProgress(newValue);
    }, [newValue]);
    return currentValue;
  };
})<number>();

export const Header: FC<Props> = ({ back, progress, title }) => {
  const currentProgress = useProgress(progress);

  return (
    <AppBar>
      {progress && (
        <LinearProgress
          variant="determinate"
          value={currentProgress}
          sx={{
            height: ".5em",
            backgroundColor: "primary.light",
            [`.${linearProgressClasses.barColorPrimary}`]: {
              backgroundColor: "primary.dark",
            },
          }}
        />
      )}
      <Container>
        <Toolbar>
          {back && (
            <IconButton onClick={back} sx={{ position: "absolute" }}>
              <ArrowBackIosNew />
            </IconButton>
          )}
          <Typography align="center" width="100%" fontWeight="bold">
            {title}
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
