export type AsyncResult<T = unknown> =
  | { type: "loading" }
  | { type: "success"; content: T }
  | { type: "failed"; error: any };

export const toAsyncResult: <T>(p: Promise<T>) => Promise<AsyncResult<T>> = <T>(
  p: Promise<T>
) =>
  p
    .then<AsyncResult<T>>((content) => ({ type: "success", content }))
    .catch<AsyncResult<T>>((error) => ({ type: "failed", error }));
