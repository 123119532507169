import React, { useEffect } from "react";

import { FormProvider } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import {
  Container,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

import { yupResolver } from "@hookform/resolvers/yup";
import { CircledIcon } from "~components/CircledIcon";
import { SingleCheckbox } from "~components/SingleCheckbox";
import { VerticalCentered } from "~components/VerticalCentered";
import { useMuiRootForm } from "~hooks/useMuiForm";
import { Address } from "~icons";
import { VerificationSchema } from "~models/Schemas";
import { UpdateRequest } from "~models/UpdateRequest";
import { esignService } from "~services/esignService";

import { ReverseColor } from "../../theme/ReverseColor";

export const Verification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!location.state || !location.state.verify) {
      navigate("/");
    }
  }, [location, navigate]);
  const methods = useMuiRootForm<UpdateRequest>({
    resolver: yupResolver(VerificationSchema),
    defaultValues: {
      email: location.state.email,
      phone: location.state.phone.replace(/^\+33/, "0"),
    },
  });
  const {
    muiRegister,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const submit = handleSubmit((updateRequest) =>
    esignService.updateInfo(updateRequest).then(() => navigate("/"))
  );
  return (
    <Container maxWidth="xs" data-mui-color-scheme="dark">
      <ReverseColor />
      <VerticalCentered>
        <Toolbar />
        <Stack textAlign="center">
          <CircledIcon
            bgcolor="rgba(var(--mui-palette-primary-mainChannel) / .1)"
            sx={{ margin: "auto" }}
          >
            <Address />
          </CircledIcon>
          <Typography variant="h2">
            Avant de commencer, vérifiez vos informations de contact
          </Typography>
          <Typography>
            Contrôlez et, si nécessaire, corrigez vos informations de contact
            pour garantir le traitement de votre dossier.
          </Typography>
          <FormProvider {...methods}>
            <TextField
              placeholder="A renseigner"
              label="Votre adresse e-mail"
              {...muiRegister("email")}
            />
            <TextField
              placeholder="A renseigner"
              label="Votre numéro de téléphone portable"
              {...muiRegister("phone")}
            />
            <SingleCheckbox
              sx={{ textAlign: "left" }}
              label="Je confirme l’exactitude des informations ci-dessus."
              {...muiRegister("confirm")}
            />
            <LoadingButton
              variant="contained"
              onClick={submit}
              loading={isSubmitting}
            >
              Valider les informations
            </LoadingButton>
          </FormProvider>
        </Stack>
      </VerticalCentered>
    </Container>
  );
};
