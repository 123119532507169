import React, { FC } from "react";

import { Stack } from "@mui/material";

import { Template } from "~components/Template";
import { ChoiceStep } from "~models/StepStatus";
import { StepProps } from "~pages/esign";
import { ChoiceCard } from "~pages/esign/choice/ChoiceCard";

export const Choice: FC<StepProps<"Choice">> = ({
  setStep,
  signatureSummary,
}) => {
  const choices = signatureSummary.choices;
  const sinisterType = signatureSummary.applicationData.sinisterType;
  return (
    <Template
      title="Votre décision"
      back={() => setStep({ value: "Conclusion" })}
      progress={20}
      maxWidth="xs"
      verticalCentered
      stepStatus={ChoiceStep}
    >
      <Stack spacing={2}>
        {choices.map((choice) => (
          <ChoiceCard
            key={choice}
            choice={choice}
            sinisterType={sinisterType}
            onClick={() => setStep({ value: "Recap", choice })}
          />
        ))}
      </Stack>
    </Template>
  );
};
