import React, { FC, useEffect } from "react";

import { Check } from "@mui/icons-material";
import {
  Timeline as MuiTimeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineConnectorClasses,
  timelineDotClasses,
  timelineItemClasses,
  timelineSeparatorClasses,
} from "@mui/lab";
import {
  Box,
  CircularProgress,
  Collapse,
  Container,
  Stack,
  Toolbar,
  Typography,
  Zoom,
  styled,
} from "@mui/material";

import { CircledIcon } from "~components/CircledIcon";
import { CG, CarContract, DocumentSignature } from "~icons";

import { ReverseColor } from "../theme/ReverseColor";

const Timeline = styled(MuiTimeline)(({ theme }) => ({
  [`.${timelineItemClasses.root}`]: {
    minHeight: "initial",
  },
  [`& .${timelineItemClasses.root}:before`]: {
    flex: 0,
    padding: 0,
  },
  [`.${timelineItemClasses.root}:last-child .${timelineSeparatorClasses.root} .${timelineConnectorClasses.root}:last-child`]:
    {
      background: "none",
    },
  [`.${timelineSeparatorClasses.root}`]: {
    maxWidth: "4em",
    flex: 1,
  },
  [`.${timelineDotClasses.root}`]: {
    borderColor: theme.palette.primary.contrastText,
    borderWidth: "1px",
    width: "1.1em",
    height: "1.1em",
    margin: "0 auto",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
  [`.${timelineConnectorClasses.root}`]: {
    backgroundColor: theme.palette.primary.contrastText,
  },
}));

const CheckIcon = styled(Check)(({ theme }) => ({
  position: "absolute",
  bottom: "-0.15em",
  right: "-0.15em",
  backgroundColor: theme.palette.success.main,
  borderRadius: "100%",
}));
const Separator = () => (
  <TimelineItem sx={{ height: "2em" }}>
    <TimelineSeparator>
      <TimelineConnector />
    </TimelineSeparator>
  </TimelineItem>
);

type ItemProps = {
  completed: boolean;
  icon: React.ReactNode;
  title: React.ReactNode;
};

const TodoItem: FC<ItemProps> = ({ title }) => (
  <TimelineItem>
    <TimelineSeparator>
      <TimelineConnector />
      <TimelineDot />
      <TimelineConnector />
    </TimelineSeparator>
    <TimelineContent>
      <Typography variant="h6" py={3}>
        {title}
      </Typography>
    </TimelineContent>
  </TimelineItem>
);

const CompletedItem: FC<ItemProps> = ({ icon, title }) => (
  <TimelineItem sx={{ alignItems: "center" }}>
    <TimelineSeparator>
      <TimelineConnector sx={{ position: "absolute", top: 0, bottom: 0 }} />
      <Zoom in={true}>
        <Box position="relative">
          <CircledIcon bgcolor="background.paper">{icon}</CircledIcon>
          <CheckIcon fontSize="small" />
        </Box>
      </Zoom>
    </TimelineSeparator>
    <TimelineContent>
      <Typography variant="h6">{title}</Typography>
    </TimelineContent>
  </TimelineItem>
);

const Item: FC<ItemProps> = (props) => {
  const ItemComponent = props.completed ? CompletedItem : TodoItem;
  return <ItemComponent {...props} />;
};

interface Props<T> {
  signed: boolean;
  completedCG: boolean;
  hasCG: boolean;
  action?: Promise<T>;
  onLoaded?: (value: T) => void;
  onError?: () => void;
}

export const Loading: <T>(props: Props<T>) => JSX.Element = ({
  signed,
  completedCG,
  hasCG,
  action,
  onLoaded,
  onError,
}) => {
  useEffect(() => {
    action && action.then(onLoaded).catch(onError);
  }, [action, onLoaded, onError]);
  return (
    <Container maxWidth="xs">
      <Toolbar />
      <ReverseColor />
      <Timeline position={"right"}>
        <Item
          icon={<CarContract primaryColor="black" />}
          title="Faire votre choix"
          completed
        />
        <Separator />
        <Item
          icon={<DocumentSignature primaryColor="black" />}
          title="Signer vos documents"
          completed={signed}
        />
        <Collapse in={hasCG} unmountOnExit>
          <Separator />
          <Item
            icon={<CG primaryColor="black" />}
            title="Completer votre carte grise"
            completed={completedCG}
          />
          <Separator />
          <Item icon="" title="Envoyer vos documents" completed={false} />
        </Collapse>
      </Timeline>
      <Stack alignItems="center">
        <CircularProgress color="inherit" />
      </Stack>
    </Container>
  );
};
