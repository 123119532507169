import React, { FC, FormEvent, useState } from "react";

import { useLoaderData, useParams } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Container,
  CssBaseline,
  Link,
  Snackbar,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";

import { ButtonGroup } from "~components/ButtonGroup";
import { CheckMark, EndSVG } from "~illustrations";
import { ValidationUrlInfo } from "~models/ValidationUrlInfo";
import { AsyncResult, toAsyncResult } from "~services/asyncResult";
import { esignService } from "~services/esignService";

import theme from "../theme";

export const ComfirmSent = () => {
  const { loginUrl } = useParams();
  const info = useLoaderData() as ValidationUrlInfo;

  return (
    <CssVarsProvider theme={theme(info.theme)}>
      <CssBaseline />

      <Toolbar />
      <Container sx={{ textAlign: "center" }}>
        <Stack alignItems="center">
          <CheckMark height="20vh" />
          <Typography fontWeight="bold">
            Votre confirmation a bien été enregistrée. Merci!
          </Typography>
        </Stack>
      </Container>
      <Box sx={{ backgroundColor: "primary.light" }} mt={8} py={8}>
        <Container>
          <Stack
            direction="row"
            mb={4}
            justifyContent="center"
            alignItems="center"
          >
            <EndSVG height="8em" width="16em" />
            <Typography>
              Vous souhaitez nous transmettre des informations complémentaires ?
              Utilisez le formulaire ci-dessous :
            </Typography>
          </Stack>
          <ContactForm loginUrl={loginUrl!} />
        </Container>
      </Box>
    </CssVarsProvider>
  );
};

interface ContactElements extends HTMLFormControlsCollection {
  message: HTMLInputElement;
}

interface ContactFormElement extends HTMLFormElement {
  readonly elements: ContactElements;
}

const ContactForm: FC<{ loginUrl: string }> = ({ loginUrl }) => {
  const [result, setResult] = useState<AsyncResult>();
  const handleSubmit = (event: FormEvent<ContactFormElement>) => {
    event.preventDefault();
    const target = event.currentTarget;
    const message = target.elements.message.value;
    setResult({ type: "loading" });
    toAsyncResult(esignService.sendMessage(loginUrl, message)).then(setResult);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack alignItems="center">
        <TextField required name="message" multiline />
        <ButtonGroup>
          <LoadingButton
            loading={result?.type === "loading"}
            disabled={result?.type === "success"}
            variant="contained"
            color="secondary"
            type="submit"
          >
            Envoyer
          </LoadingButton>
          <Snackbar open={result?.type === "failed"}>
            <Alert color="error">
              Une erreur s&apos;est produite, veuillez réessayer ou
              contactez-nous sur{" "}
              <Link href="mailto:helpdesk@evalucar.com">
                helpdesk@evalucar.com
              </Link>
              .
            </Alert>
          </Snackbar>
          <Snackbar open={result?.type === "success"}>
            <Alert color="success">Informations envoyées.</Alert>
          </Snackbar>
        </ButtonGroup>
      </Stack>
    </form>
  );
};
