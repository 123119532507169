import axios, { AxiosResponse } from "axios";

const esignApi = process.env.REACT_APP_ESIGN_API_URL || "http://localhost:8081";

const instance = axios.create({
  baseURL: esignApi,
  timeout: 60000,
  transformResponse: (data) => {
    try {
      return (
        data &&
        JSON.parse(data, (key, value) =>
          ["signedAt", "signatureDate", "expiresAt"].includes(key) && value
            ? new Date(value)
            : value
        )
      );
    } catch {
      return data;
    }
  },
});

instance.interceptors.request.use((config) => {
  const item = localStorage.getItem("esignToken");
  const token = item && JSON.parse(item);
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

export const getData = (response: AxiosResponse) => response.data;

export default instance;
