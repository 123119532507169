import React from "react";

import { Container, Paper, Stack, Typography } from "@mui/material";

import { VerticalCentered } from "~components/VerticalCentered";

type Props = {
  endOfMaintenance: string;
};

const MaintenancePage = (props: Props) => {
  const { endOfMaintenance } = props;

  return (
    <div>
      <img
        src="/static/img/brands/logo.svg"
        alt="Evalucar logo"
        height={34}
        width={100}
        style={{ marginLeft: "4px", marginBottom: "-6px" }}
      />
      <Container sx={{ textAlign: "center" }} maxWidth="md">
        <VerticalCentered>
          <Stack>
            <Typography fontWeight="bold" variant="h6">
              Notre service est actuellement en maintenance.
            </Typography>
            <Typography>
              Il sera de nouveau disponible dès{" "}
              <span style={{ fontWeight: "bold" }}>{endOfMaintenance}</span>.
            </Typography>
            <Typography>Merci de votre compréhension.</Typography>
          </Stack>
        </VerticalCentered>
        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            padding: (theme) => theme.spacing(1, 0, 2, 0),
            borderRadius: 0,
          }}
        >
          <Typography fontSize="10px">
            © {new Date().getFullYear()} Par Evalucar
          </Typography>
        </Paper>
      </Container>
    </div>
  );
};

export default MaintenancePage;
