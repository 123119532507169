import React, { FC } from "react";

import { FormControl, Grid, TextField, Typography } from "@mui/material";

import { MaskedTextField } from "~components/TextMaskInput";
import { useMuiForm } from "~hooks/useMuiForm";
import { SignatureSummary } from "~models/SignatureSummary";

export const RenteeAddress: FC<{ signatureSummary: SignatureSummary }> = ({
  signatureSummary,
}) => {
  const { register } = useMuiForm("renterInformation");
  const {
    streetNumber,
    streetExtension,
    streetName,
    streetType,
    zipCode,
    city,
  } = signatureSummary.previousOwnerAddress;
  return (
    <FormControl fullWidth>
      <Typography fontWeight="bold" my={2}>
        Adresse postale à indiquer sur les documents
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={3} sm={2}>
          <TextField
            label="N°"
            defaultValue={streetNumber}
            {...register("streetNumber")}
          />
        </Grid>
        <Grid item xs={3} sm={2}>
          <TextField
            label="Ext."
            defaultValue={streetExtension}
            {...register("streetExtension")}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            label="Type de voie"
            defaultValue={streetType}
            {...register("streetType")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Nom de la voie"
            defaultValue={streetName}
            {...register("streetName")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MaskedTextField
            label="Code postal"
            defaultValue={zipCode}
            {...register("zipCode")}
            mask="00000"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Ville" defaultValue={city} {...register("city")} />
        </Grid>
      </Grid>
    </FormControl>
  );
};
