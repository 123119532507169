import React, { FC } from "react";

import { Box, Container, Stack, Typography } from "@mui/material";

import { Header } from "~components/Header";
import { VerticalCentered } from "~components/VerticalCentered";
import { MaintenanceSVG } from "~illustrations";
import { Footer } from "~pages/auth/Footer";

interface Props {
  onBack?: () => void;
}

export const Error: FC<Props> = ({ onBack }) => (
  <Container sx={{ textAlign: "center" }} maxWidth="xs">
    <Header title="" back={onBack} />
    <VerticalCentered>
      <Stack>
        <Box height="25vh">
          <MaintenanceSVG height="100%" />
        </Box>
        <Typography variant="h2">Error 500</Typography>
        <Typography>
          Une erreur est survenue. Veuillez réessayer en cliquant sur le bouton
          précédent.
        </Typography>
      </Stack>
    </VerticalCentered>
    <Footer />
  </Container>
);
