import * as Yup from "yup";

export const LegalPersonInfoSchema = Yup.object().shape({
  legalPersonInformation: Yup.object()
    .typeError("Renseignez le champ")
    .shape({
      confirm: Yup.bool().oneOf(
        [true],
        "Vous devez confirmer être habilité(e) à signer les documents"
      ),
      firstName: Yup.string().required("Renseignez votre prénom").trim(),
      lastName: Yup.string().required("Renseignez votre nom").trim(),
      position: Yup.string().required("Renseignez votre fonction").trim(),
      siret: Yup.string()
        .nullable()
        .when("$currentUser.isHolder", {
          is: true,
          then: (schema) =>
            schema
              .transform((siret) => siret && siret.replaceAll(" ", ""))
              .matches(/^\d{14}$/, "SIRET invalide")
              .required("Renseignez votre SIRET"),
        }),
    }),
});

export const CompanyInfoSchema = Yup.object().shape({
  companyInfo: Yup.object().shape({
    legalStatus: Yup.string().required("Renseignez la forme juridique").trim(),
    companyName: Yup.string()
      .required("Renseignez le nom de la société")
      .trim(),
    shareCapital: Yup.string().required("Renseignez le capital social").trim(),
    headOfficeStreet: Yup.string()
      .required("Renseignez l'adresse du siège")
      .trim(),
    headOfficePostalCode: Yup.string()
      .required("Renseignez le code postale")
      .trim(),
    headOfficeCity: Yup.string().required("Renseignez la commune").trim(),
    headOfficeCountry: Yup.string().required("Renseignez le pays").trim(),
  }),
});

export const RenteeInfoSchema = LegalPersonInfoSchema.concat(
  Yup.object().shape({
    renterInformation: Yup.object().shape({
      hasCG: Yup.boolean().when("$choice", {
        is: "sell",
        then: (schema) =>
          schema
            .typeError("Renseignez le champ")
            .required("Renseignez le champ"),
      }),
      streetNumber: Yup.string().trim(),
      streetExtension: Yup.string().trim(),
      streetType: Yup.string().trim(),
      streetName: Yup.string().required("Renseignez le nom de la voie").trim(),
      zipCode: Yup.string()
        .matches(/\d{5}/, "Renseignez un code postal valide")
        .required("Renseignez le code postal"),
      city: Yup.string().required("Renseignez la ville").trim(),
    }),
  })
);

export const RepairMandateInfoSchema = Yup.object({
  repairSupport: Yup.boolean()
    .typeError("Renseignez le champ")
    .required("Renseignez le champ"),
  garageName: Yup.string()
    .nullable()
    .when("repairSupport", {
      is: true,
      then: (schema) => schema.required("Renseignez le nom du garage").trim(),
    }),
});

const phoneRegex = /^((\+)33|0|\+262)[6-7](\d{2}){4}$/;
export const CoholdersInformationSchema = Yup.object({
  coholdersInformation: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string()
          .nullable()
          .when("isLegalPerson", {
            is: false,
            then: (schema) =>
              schema
                .required("Renseignez le prénom")
                .trim()
                .test(
                  "not-holder-name",
                  "Vous ne pouvez pas vous déclarer en tant que cotitulaire. Vous devez renseigner les informations du cotitulaire présent sur la carte grise sans quoi les documents de cession seront refusés",
                  (firstName, { options: { context }, path }) => {
                    const index = parseInt(path.split("[")[1].split("]")[0]);
                    const isDefaultValue =
                      context!.coholdersInformation[index].firstName ===
                      firstName;
                    const isNotHolderValue =
                      firstName !== context!.currentUser.firstName;
                    return isDefaultValue || isNotHolderValue;
                  }
                ),
          }),
        lastName: Yup.string()
          .nullable()
          .when("isLegalPerson", {
            is: false,
            then: (schema) => schema.required("Renseignez le nom").trim(),
          }),
        phone: Yup.string()
          .nullable()
          .when("isLegalPerson", {
            is: false,
            then: (schema) =>
              schema
                .matches(
                  phoneRegex,
                  "Renseignez un numéro de téléphone portable valide"
                )
                .transform(
                  (n) => n && n.replace(/^0/, "+33").replaceAll(" ", "")
                )
                .required("Renseignez le numéro de téléphone"),
          }),
        email: Yup.string()
          .email("Renseignez un e-mail valide")
          .required("Renseignez l'e-mail")
          .trim(),
        isLegalPerson: Yup.boolean().required("Renseignez le status"),
      })
    )
    .required(),
})
  .test("unique-mails", "Les emails doivent être distincts.", (infos) => {
    const mails = infos.coholdersInformation.map((info) => info.email);
    return mails.length === new Set(mails).size;
  })
  .test(
    "not-holder-mail",
    "L'email du cotitulaire ne peut pas être identique à celui du titulaire.",
    (infos, { options: { context } }) => {
      const mails = infos.coholdersInformation.map((info) => info.email);
      return mails.every((mail) => mail !== context!.currentUser.email);
    }
  );

export const ConditionsSchema = Yup.object({
  conditions: Yup.array().of(Yup.bool().oneOf([true], "Vous devez confirmer")),
});

export const RatingSchema = Yup.object().shape({
  rating: Yup.number().required("Vous devez evaluer votre experience"),
  comment: Yup.string().trim(),
});

export const VerificationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Renseignez un e-mail valide")
    .required("Renseignez votre e-mail"),
  phone: Yup.string()
    .matches(phoneRegex, "Renseignez un numéro de téléphone portable valide")
    .transform((n) => n && n.replace(/^0/, "+33").replaceAll(" ", ""))
    .required("Renseignez votre numéro de téléphone"),
  confirm: Yup.bool().oneOf([true], "Confirmez les informations"),
});
