import { FC } from "react";

import { CarContract, CarCrash, CarRepair, ColorIconProps } from "~icons";
import { SinisterType } from "~models/SinisterType";
import { User } from "~models/User";
import { UserChoice } from "~models/UserChoice";

export const ChoiceIcon: { [key in UserChoice]: FC<ColorIconProps> } = {
  sell: CarContract,
  repair: CarRepair,
  keep: CarCrash,
};

export const ChoiceTitle = (
  choice: UserChoice,
  sinisterType?: SinisterType
): string => {
  switch (choice) {
    case "sell":
      return "Je cède mon véhicule à mon assureur";

    case "keep":
      return "Je conserve mon véhicule en l’état";

    case "repair":
      if (
        sinisterType === "CONSERVATOIRE_AXA_AVEC_VGE" ||
        sinisterType === "CONSERVATOIRE_AXA_SANS_VGE"
      ) {
        return "Je refuse de céder mon véhicule";
      }
      return "Je fais réparer mon véhicule";

    default:
      return "";
  }
};

export const ChoiceLabelFor = (user: User) =>
  user.signature ? ChoiceLabel(user.signature.choice) : "";

export const ChoiceLabel = (choice: UserChoice) =>
  (choice === "repair" && "faire réparer") ||
  (choice === "sell" && "céder") ||
  (choice === "keep" && "conserver en l'état");
