export const formatDate = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return date.toLocaleDateString("fr-FR", options);
};

export const generateEndOfMaintenanceString = (
  endDate: Date,
  dateNow: Date
): string => {
  const isToday = dateNow.getDate() === endDate.getDate();
  const isTomorrow = dateNow.getDate() + 1 === endDate.getDate();

  let dayDescription = "";
  if (isToday) {
    dayDescription = "aujourd'hui";
  } else if (isTomorrow) {
    dayDescription = "demain";
  } else {
    dayDescription = formatDate(endDate);
  }

  return isToday || isTomorrow
    ? `${dayDescription} (${formatDate(endDate)})`
    : dayDescription;
};

export const checkMaintenanceMode = (): {
  isMaintenaceMode: boolean;
  endOfMaintenance: string;
} => {
  const dateNow = new Date();
  const startDate = process.env.REACT_APP_START_DATE_MAINTENANCE
    ? new Date(process.env.REACT_APP_START_DATE_MAINTENANCE)
    : dateNow;
  const endDate = process.env.REACT_APP_END_DATE_MAINTENANCE
    ? new Date(process.env.REACT_APP_END_DATE_MAINTENANCE)
    : dateNow;

  const isMaintenaceMode = dateNow > startDate && dateNow < endDate;
  return {
    isMaintenaceMode,
    endOfMaintenance: generateEndOfMaintenanceString(endDate, dateNow),
  };
};
