import React, { FC } from "react";

import { Button, Stack, Toolbar, Typography } from "@mui/material";

import { Header } from "~components/Header";

interface Props {
  onModify: () => void;
}

export const CookieRefused: FC<Props> = ({ onModify }) => (
  <>
    <Header title="Refuser" back={onModify} />
    <Stack alignItems="center" textAlign="justify" pb={10}>
      <Toolbar></Toolbar>
      <Typography variant="h1">Contactez votre expert</Typography>
      <Typography>Vous ne souhaitez pas utiliser notre service ?</Typography>
      <Typography>
        Vous devez contacter votre expert afin de lui faire part de votre
        décision. Votre dossier sera traité par courrier postal ou messagerie
        électronique, nécessitera potentiellement des actions supplémentaires de
        votre part (documents à imprimer pour signature par exemple) et
        impliquera un délai de traitement plus long.
      </Typography>
      <Typography>
        Vous pouvez modifier votre choix en cliquant ci-dessous :{" "}
      </Typography>
      <Button variant="outlined" color="secondary" onClick={onModify}>
        Modifier mon choix
      </Button>
    </Stack>
  </>
);
