import React, { FC } from "react";

import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  TextFieldProps,
} from "@mui/material";

type Props = {
  options: (Omit<FormControlLabelProps, "control"> &
    Pick<TextFieldProps, "helperText" | "error">)[];
} & Pick<
  TextFieldProps,
  "helperText" | "error" | "required" | "label" | "inputRef"
> &
  RadioGroupProps;

export const RadioButtons: FC<Props> = ({
  helperText,
  error,
  required,
  label,
  options,
  inputRef,
  ...RadioGroupProps
}) => (
  <FormControl required={required} error={error}>
    {label && <FormLabel>{label}</FormLabel>}
    <RadioGroup {...RadioGroupProps}>
      {options.map(({ error, helperText, ...option }, i) => (
        <FormControlLabel
          key={i}
          control={<Radio size="small" />}
          {...option}
        />
      ))}
    </RadioGroup>
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
  </FormControl>
);
