import React, { FC } from "react";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";

import { BottomSheet } from "~components/BottomSheet";
import { ButtonGroup } from "~components/ButtonGroup";
import { NextButton } from "~components/NextButton";
import { useToggle } from "~hooks/useToggle";
import { CarInsurance } from "~illustrations";
import { UserLoginInfo } from "~models/UserLoginInfo";

import CGU from "./CGU.mdx";
import Privacy from "./Privacy.mdx";

interface Props {
  accept: () => void;
  refuse: () => void;
  info: UserLoginInfo;
}

export const Cookie: FC<Props> = ({ accept, refuse, info }) => {
  const [showCGU, toggleCGU] = useToggle();
  const [showPrivacy, togglePrivacy] = useToggle();

  return (
    <Stack alignItems="center" pb={10} textAlign="justify">
      <Toolbar />
      <CarInsurance height="11em" />
      <Typography variant="h1" textAlign="center">
        Bienvenue
      </Typography>
      <Typography>
        Afin de vous offrir la meilleure expérience possible, notre site utilise
        des Cookies. Ces derniers sont strictement nécessaires au bon
        fonctionnement du service.
      </Typography>
      <Typography>
        Pour plus d’informations sur le traitement de vos données, vous pouvez
        consulter nos{" "}
        <Link onClick={toggleCGU}>conditions générales d'utilisation</Link> et
        notre <Link onClick={togglePrivacy}>politique de confidentialité</Link>.
      </Typography>
      <Typography>
        En cliquant sur le bouton ci-dessous, vous acceptez les conditions
        d’utilisation du service.
      </Typography>
      <ButtonGroup>
        <NextButton onClick={accept} color="secondary">
          Accepter
        </NextButton>
        <Link onClick={refuse} textAlign="center">
          Refuser
        </Link>
      </ButtonGroup>
      <BottomSheet open={showCGU} onClose={toggleCGU} scroll="paper">
        <DialogTitle>Conditions générales d'utilisation</DialogTitle>
        <DialogContent sx={{ h2: { color: "primary.main" } }}>
          <CGU />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleCGU} variant="contained">
            Ok
          </Button>
        </DialogActions>
      </BottomSheet>
      <BottomSheet open={showPrivacy} onClose={togglePrivacy}>
        <DialogTitle>Politique de confidentialité</DialogTitle>
        <DialogContent sx={{ h2: { color: "primary.main" } }}>
          <Privacy />
        </DialogContent>
        <DialogActions>
          <Button onClick={togglePrivacy} variant="contained">
            Ok
          </Button>
        </DialogActions>
      </BottomSheet>
    </Stack>
  );
};
