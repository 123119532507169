import React, { Dispatch, FC, SetStateAction, useState } from "react";

import { Outlet, useLoaderData, useOutletContext } from "react-router-dom";

import { CssBaseline } from "@mui/material";
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";

import { SignatureSummary } from "~models/SignatureSummary";

import theme from "../theme";

interface Props {
  children?: React.ReactNode;
}

export const EsignLayout: FC<Props> = ({ children }) => {
  const initialSummary = useLoaderData() as SignatureSummary;
  const [signatureSummary, setSummary] = useState(initialSummary);

  return (
    <CssVarsProvider theme={theme(signatureSummary.configData.theme)}>
      <CssBaseline />
      {children}
      <Outlet context={[signatureSummary, setSummary]} />
    </CssVarsProvider>
  );
};

export function useSignatureSummary() {
  return useOutletContext<
    [SignatureSummary, Dispatch<SetStateAction<SignatureSummary>>]
  >();
}
