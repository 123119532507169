import React, { useState } from "react";

import { Button, Link, Typography } from "@mui/material";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ButtonGroup } from "~components/ButtonGroup";
import { NextButton } from "~components/NextButton";
import { SingleCheckbox } from "~components/SingleCheckbox";
import { Template } from "~components/Template";
import { useMuiRootForm } from "~hooks/useMuiForm";
import { Finalize, FinalizeLater } from "~illustrations";
import { CoholderFinalizationStep, SignLaterStep } from "~models/StepStatus";
import { PosthocStep } from "~pages/esign/posthoc";

export const CoholderFinalization: PosthocStep = ({ next }) => {
  const {
    muiRegister: register,
    watch,
    handleSubmit,
  } = useMuiRootForm({
    resolver: yupResolver(
      Yup.object().shape({
        hasCG: Yup.bool().oneOf([true], "Veuillez confirmer"),
      })
    ),
  });
  const [signLater, setSignLater] = useState(false);
  const onSignLater = () => setSignLater(true);
  const onNext = handleSubmit(() => next && next());

  if (signLater) {
    return (
      <Template
        title="Finalisation de mon dossier"
        textAlign="center"
        stepStatus={SignLaterStep}
      >
        <FinalizeLater height="25vh" />
        <Typography fontWeight="bold">
          Vous n’avez pas encore finalisé votre dossier !
        </Typography>
        <Typography>
          Pour réaliser les dernières actions, rapprochez vous du titulaire ou
          cliquez sur le bouton ci-dessous.
        </Typography>
        <Typography>
          Un email récapitulatif vous a été envoyé ainsi qu’au titulaire.
        </Typography>
        {next && (
          <ButtonGroup>
            <Button variant="outlined" onClick={next} fullWidth>
              Accéder aux consignes
            </Button>
          </ButtonGroup>
        )}
        <Link onClick={window.close}>Fermer la page et revenir plus tard</Link>
      </Template>
    );
  } else {
    return (
      <Template
        title="Finalisation de mon dossier"
        textAlign="left"
        progress={50}
        stepStatus={CoholderFinalizationStep}
      >
        <Finalize height="25vh" />
        <Typography fontWeight="bold" textAlign="center">
          Pour finaliser votre dossier et permettre le remboursement, le
          titulaire et le cotitulaire doivent compléter la carte grise
        </Typography>
        <SingleCheckbox
          label={
            <Typography>
              Je suis en possession de la Carte Grise et le titulaire est
              disponible pour réaliser les actions avec moi.
            </Typography>
          }
          {...register("hasCG")}
          checked={!!watch("hasCG")}
        />
        <ButtonGroup>
          <NextButton onClick={onNext}>Suivant</NextButton>
          <Button variant="outlined" fullWidth onClick={onSignLater}>
            Le faire plus tard
          </Button>
        </ButtonGroup>
      </Template>
    );
  }
};
