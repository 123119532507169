import React, { FC, useEffect } from "react";

import { useFormContext } from "react-hook-form";

import {
  Collapse,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { RadioButtons } from "~components/RadioButtons";
import { MaskedTextField } from "~components/TextMaskInput";
import { useMuiForm } from "~hooks/useMuiForm";
import { CoholdersInformationSchema } from "~models/Schemas";
import { CoholderInformation } from "~models/UserChoiceOptions";
import { StepProps } from "~pages/esign";
import { RecapStep } from "~pages/esign/recap/index";

const CoholderInfo: FC<{ coholder: CoholderInformation; i: number }> = ({
  coholder,
  i,
}) => {
  const { register, watch } = useMuiForm(`coholdersInformation.${i}`);

  useEffect(() => {
    register("index", { value: coholder.index });
  }, [coholder.index, register]);

  const isNaturalPerson = watch("isLegalPerson") === "false";

  return (
    <Stack width="100%">
      <RadioButtons
        label={`Le cotitulaire ${coholder.name} est une personne :`}
        row
        options={[
          {
            label: "Physique",
            value: false,
            ...register("isLegalPerson"),
            checked: watch("isLegalPerson") === "false",
          },
          {
            label: "Morale (société, association, etc ...)",
            value: true,
            ...register("isLegalPerson"),
            checked: watch("isLegalPerson") === "true",
          },
        ]}
        {...register("isLegalPerson")}
      />
      <Collapse in={!!watch("isLegalPerson")}>
        <Stack spacing={2}>
          {isNaturalPerson && (
            <TextField
              label="Nom"
              defaultValue={coholder.lastName}
              {...register("lastName")}
              InputProps={{ readOnly: true }}
            />
          )}
          {isNaturalPerson && (
            <TextField
              label="Prénom"
              defaultValue={coholder.firstName}
              {...register("firstName")}
              InputProps={{
                readOnly: coholder.firstName
                  ? coholder.firstName.length > 0
                  : false,
              }}
            />
          )}
          <TextField type="email" label="E-mail" {...register("email")} />
          {isNaturalPerson && (
            <MaskedTextField
              type="tel"
              label="Téléphone portable"
              mask="+{33} # 00 00 00 00"
              definitions={{ "#": /[1-9]/ }}
              {...register("phone")}
            />
          )}
        </Stack>
      </Collapse>
    </Stack>
  );
};
const CoholdersInfoFC: FC<StepProps<"Recap">> = ({ signatureSummary }) => {
  const infos = signatureSummary.coholdersInformation;
  const {
    formState: { errors },
  } = useFormContext();

  const names = infos.map((info) => info.name).join(", ");

  return (
    <>
      <Typography>
        Le cotitulaire <b>{names}</b> est indiqué sur la carte grise (champ
        C.4.1). Vous devez renseigner les informations de contact afin de
        poursuivre votre dossier:
      </Typography>
      {infos.map((coholder, i) => (
        <CoholderInfo coholder={coholder} key={coholder.index} i={i} />
      ))}
      <FormHelperText error>
        {errors[""]?.message ? <>{errors[""].message}</> : ""}
      </FormHelperText>
    </>
  );
};

export const CoholdersInfo: RecapStep = Object.assign(CoholdersInfoFC, {
  title: "Infos",
  schema: CoholdersInformationSchema,
});
