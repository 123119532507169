import React, { FC } from "react";

import { SvgIcon } from "@mui/material";

import { ColorIconProps, Path } from "~icons";

export const CarContract: FC<ColorIconProps> = ({
  primaryColor,
  secondaryColor = "primary.dark",
  size,
}) => (
  <SvgIcon inheritViewBox fontSize={size}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 391">
      <Path
        color={primaryColor}
        d="M177 3a113 113 0 0 0-97 55l-7 16-20 50-12 28a64 64 0 0 0-41 59v48a63 63 0 0 0 16 42v54a32 32 0 0 0 32 32h32c18 0 32-14 32-32v-32h121V147H112l20-49c7-19 25-31 44-31h57V49c0-18 6-34 17-46zM80 195c19 0 48 29 48 48s-29 16-48 16-32-13-32-32 13-32 32-32z"
      />
      <Path
        color={secondaryColor}
        d="M293 0c-24 0-44 22-44 49v293c0 27 20 49 44 49h175c24 0 44-22 44-49V122h-88c-12 0-22-11-22-24V0Zm131 0v98h88ZM304 49h44c6 0 11 5 11 12s-5 12-11 12h-44c-6 0-11-5-11-12s5-12 11-12zm0 49h44c6 0 11 5 11 12s-5 12-11 12h-44c-6 0-11-5-11-12s5-12 11-12zm11 73h131c12 0 22 11 22 24v49c0 14-10 25-22 25H315c-12 0-22-11-22-25v-49c0-13 10-24 22-24zm0 24v49h131v-49zm98 122h44c6 0 11 6 11 13 0 6-5 12-11 12h-44c-6 0-11-6-11-12 0-7 5-13 11-13z"
      />
    </svg>
  </SvgIcon>
);
