const RepairFees = (value: string, label: string) => ({
  label:
    "En fonction du montant des réparations, des frais peuvent rester à ma charge.",
  info: `Si le montant des réparations dépasse la valeur de remplacement de ${value}€ ${label}, la différence restera à ma charge. Le montant définitif du remboursement sera communiqué ultérieurement par l'assureur en fonction des garanties.`,
});
const CantSell = (insurance: string) => ({
  label: "Je ne peux pas vendre mon véhicule à un particulier.",
  info: `En l’état, une opposition au transfert du certificat d’immatriculation (“gage”) a été ou va être demandée par ${insurance} auprès du Ministère de l’intérieur, m’empêchant de céder ou vendre mon véhicule à un particulier.`,
});
const ProfessionallyRepaired = {
  label: "J'ai l'obligation de faire réparer par un professionnel.",
  info: "Conformément à la loi, j'ai l'obligation de faire réaliser l'ensemble des travaux de remise en état par un professionnel. de l'automobile",
};
const FollowExpert = {
  label: "J'ai l'obligation de faire suivre les travaux par un expert.",
  info: "Avant toute réparation, je dois mandater un expert automobile qui s'assurera de la conformité de la remise en état durant les travaux.",
};
const CantCancelContract = {
  label: "Je ne peux pas résilier le contrat d'assurance en l'état.",
  info: "Le contrat d'assurance ne peut pas être résilié tant que je ne fournis pas un justificatif de destruction délivré par un professionnel agréé ou un justificatif de souscription auprès d'un nouvel assureur ou un justificatif de réparation (rapport de l’expert chargé du suivi des travaux).",
};
const CantDrive = {
  label:
    "Je n'ai pas le droit de circuler avec mon véhicule sur la voie publique.",
  info: "Conformément à la loi, en l'état, mon véhicule gravement endommagé n'est pas autorisé à circuler sur la voie publique.",
};
const ResidualValueDeducted = (value: string) => ({
  label:
    "La valeur résiduelle de mon véhicule sera déduite de mon indemnisation.",
  info: `La valeur résiduelle (valeur après sinistre) de mon véhicule ${value}et les éventuels frais de gardiennage seront déduits de mon indemnisation. Le montant définitif du remboursement sera communiqué ultérieurement par l'assureur en fonction des garanties.`,
});
const ExtraFees = (procedure: string) => ({
  label: "Je peux avoir des frais supplémentaires à ma charge.",
  info: `Je devrai supporter les frais de remise en conformité des éléments de sécurité, sans relation avec le sinistre, qui apparaîtraient nécessaires dans le cadre de la procédure des ${procedure}.`,
});
const PersonalStuffs = {
  label: "Je dois récupérer mes effets personnels dans les plus brefs délais.",
  info: "Je récupère mon badge autoroute, bip de parking, mes affaires dans la boite à gants, les différents rangements, le coffre, le sous coffre... Je laisse tout ce qui concerne le véhicule : notices, carnet d'entretien, écrou antivol, kit de remorquage...",
};
const SellerCantDrive = {
  label:
    "Après signature, je n'aurai plus le droit de circuler avec le véhicule.",
  info: "Après la signature des documents de cession, le véhicule appartiendra à ma compagnie d'assurance, je ne dois plus l'utiliser en attendant sa récupération par le professionnel mandaté par mon assureur.",
};
const NoDisassembly = {
  label: "Je m'engage à ne démonter aucune pièce sur le véhicule.",
  info: "Je ne dois démonter aucune pièce, aucun accessoire présent lors de l'expertise du véhicule sous peine de voir le montant associé déduit de mon indemnisation par mon assureur.",
};

const SellWithoutAxaType = [
  {
    label:
      "Le montant définitif de l'indemnisation me sera communiqué ultérieurement par l'assureur en fonction des garanties.",
  },
  {
    label:
      'Je dois impérativement être titulaire du certificat d\'immatriculation ("carte grise").',
    info: "Si ce n'est pas le cas, je dois régulariser la situation au préalable.",
  },
  PersonalStuffs,
  SellerCantDrive,
  NoDisassembly,
];

const sellWithAxaType = (residualValue: string, label: string) => [
  {
    label: `Le montant de mon remboursement sera basé sur la valeur après sinistre de mon véhicule (${residualValue}€ ${label}).`,
    info: "Le montant définitif du remboursement sera communiqué ultérieurement par mon assureur en fonction de mon contrat.",
  },
  {
    label:
      "Si mon sinistre est finalement pris en charge par mon assureur, un complément d'indemnisation me sera versé.",
    info: "Le cas échéant, mon assureur ou l'expert en charge de mon sinistre me communiqueront cette information.",
  },
  {
    label:
      "Je dois impérativement être titulaire du certificat d'immatriculation (carte grise).",
    info: "si ce n'est pas le cas, je dois régulariser la situation au préalable.",
  },
  PersonalStuffs,
  SellerCantDrive,
  NoDisassembly,
];

const repairAxaWithVGE = (insurance: string) => [
  {
    label:
      "Des frais de gardiennage et de destruction peuvent rester à ma charge.",
    info: "Il est recommandé de récupérer votre véhicule dans les plus brefs délais et de vous rapprocher de votre assurance pour connaitre les modalités d'indemnisation.",
  },
  {
    label:
      "Je n'ai pas le droit de circuler avec mon véhicule sur la voie publique.",
    info: "Conformément à la loi, en l'état, mon véhicule gravement endommagé n'est pas autorisé à circuler sur la voie publique.",
  },
  {
    label: "Je ne peux pas vendre mon véhicule à un particulier.",
    info: `En l’état, une opposition au transfert du certificat d’immatriculation (“gage”) a été ou va être demandée par ${insurance} auprès du Ministère de l’intérieur, m’empêchant de céder ou vendre mon véhicule à un particulier.`,
  },
  {
    label: "Je ne peux pas résilier le contrat d'assurance en l'état.",
    info: "Le contrat d'assurance ne peut être résilié tant que je ne fournis pas un justificatif de destruction délivré par un professionnel agréé ou un justificatif de souscription auprès d'un nouvel assureur ou un justificatif de réparation (rapport de l’expert chargé du suivi des travaux).",
  },
];

const RepairAxaWithoutVGE = [
  {
    label:
      "Des frais de gardiennage et de destruction peuvent rester à ma charge.",
    info: "Il est recommandé de récupérer votre véhicule dans les plus brefs délais et de vous rapprocher de votre assurance pour connaitre les modalités d'indemnisation.",
  },
];

const text = {
  sell: ({
    residualValue,
    label,
  }: {
    residualValue: string;
    label: string;
  }) => ({
    VEI_SANS_VGE: SellWithoutAxaType,
    VEI_AVEC_VGE: SellWithoutAxaType,
    RIV_SANS_VGE: SellWithoutAxaType,
    RIV_AVEC_VGE: SellWithoutAxaType,
    VNR: SellWithoutAxaType.slice(0, 2),
    TNR: SellWithoutAxaType,
    CONSERVATOIRE_AXA_AVEC_VGE: sellWithAxaType(residualValue, label),
    CONSERVATOIRE_AXA_SANS_VGE: sellWithAxaType(residualValue, label),
  }),
  repair: ({
    replacementValue,
    replacementValueLabel,
    insurance,
  }: {
    replacementValue: string;
    replacementValueLabel: string;
    insurance: string;
  }) => ({
    VEI_SANS_VGE: [
      RepairFees(replacementValue, replacementValueLabel),
      CantSell(insurance),
      ProfessionallyRepaired,
      FollowExpert,
      CantCancelContract,
      ExtraFees("Véhicules Économiquement Irréparables (VEI)"),
    ],
    VEI_AVEC_VGE: [
      RepairFees(replacementValue, replacementValueLabel),
      CantDrive,
      CantSell(insurance),
      ProfessionallyRepaired,
      FollowExpert,
      CantCancelContract,
      ExtraFees("Véhicules Gravement Endommagés (VGE)"),
    ],
    RIV_SANS_VGE: [RepairFees(replacementValue, replacementValueLabel)],
    RIV_AVEC_VGE: [
      RepairFees(replacementValue, replacementValueLabel),
      CantDrive,
      CantSell(insurance),
      ProfessionallyRepaired,
      FollowExpert,
      CantCancelContract,
      ExtraFees("Véhicules Gravement Endommagés (VGE)"),
    ],
    VNR: [],
    TNR: [],
    CONSERVATOIRE_AXA_AVEC_VGE: repairAxaWithVGE(insurance),
    CONSERVATOIRE_AXA_SANS_VGE: RepairAxaWithoutVGE,
  }),
  keep: ({
    insurance,
    residualValue,
  }: {
    insurance: string;
    residualValue: string;
  }) => ({
    VEI_SANS_VGE: [
      ResidualValueDeducted(residualValue),
      CantSell(insurance),
      CantCancelContract,
    ],
    VEI_AVEC_VGE: [
      ResidualValueDeducted(residualValue),
      CantDrive,
      CantSell(insurance),
      CantCancelContract,
    ],
    RIV_SANS_VGE: [ResidualValueDeducted(residualValue)],
    RIV_AVEC_VGE: [
      ResidualValueDeducted(residualValue),
      CantDrive,
      CantSell(insurance),
      CantCancelContract,
    ],
    VNR: [],
    TNR: [
      ResidualValueDeducted(residualValue),
      CantDrive,
      {
        label: "Je ne peux pas résilier le contrat d'assurance en l'état.",
        info: "Le contrat d'assurance ne peut pas être résilié tant que je ne fournis pas un justificatif de destruction délivré par un professionnel agréé ou un justificatif de souscription auprès d'un nouvel assureur.",
      },
    ],
    CONSERVATOIRE_AXA_AVEC_VGE: [],
    CONSERVATOIRE_AXA_SANS_VGE: [],
  }),
};
export default text;
