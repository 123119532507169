import React, { FC } from "react";

import { SvgIcon } from "@mui/material";

import { ColorIconProps, Path } from "~icons/index";

export const CG: FC<ColorIconProps> = ({
  primaryColor,
  secondaryColor = "primary.dark",
  size,
}) => (
  <SvgIcon inheritViewBox fontSize={size}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
      <Path
        color={secondaryColor}
        d="M192 0a64 64 0 0 0-64 64v312L512 75V64a64 64 0 0 0-64-64H256zm16 61.8h224a16 16 0 0 1 16 16 16 16 0 0 1-16 16H208a16 16 0 0 1-16-16 16 16 0 0 1 16-16zm0 64h143a16 16 0 0 1 16 16 16 16 0 0 1-16 16H208a16 16 0 0 1-16-16 16 16 0 0 1 16-16zm0 64.1h60.9a16 16 0 0 1 16 16 16 16 0 0 1-16 16h-61a16 16 0 0 1-16-16 16 16 0 0 1 16-16zm304 7L143.3 487.3l-1.1.9A64 64 0 0 0 192 512h256a64 64 0 0 0 64-64v-2.2zM362.2 360.1a26 26 0 0 1 26 26v2.1c0 5.7-.3 11.3-1 16.9l33.2-10a8.7 8.7 0 0 1 10.7 11l-4.9 14.6h22.6a8.6 8.6 0 1 1 0 17.4h-34.6a8.7 8.7 0 0 1-8.2-11.4l3.4-10.2-25.6 7.7c-3.2 14.3-8.5 28-15.8 40.7l-3.7 6.6a19.6 19.6 0 0 1-36.7-9.8v-19.4a26 26 0 0 1 18.5-25l22.9-6.8a131 131 0 0 0 1.9-22.3v-2a8.6 8.6 0 1 0-17.3 0v8.6a8.6 8.6 0 1 1-17.3 0V386a26 26 0 0 1 26-26zm2.2 70L351 434a8.7 8.7 0 0 0-6.2 8.3v19.5a2.3 2.3 0 0 0 4.3 1.1l3.8-6.6c4.7-8.3 8.5-17.1 11.4-26.3zM308 451h11v13h-10.9a6.5 6.5 0 1 1 0-13zm76.3 0h83.9a6.5 6.5 0 1 1 0 13h-90a166 166 0 0 0 6.1-13z"
      />
      <Path
        color={primaryColor}
        d="M9.2 469.1a24 24 0 0 0 29.6 37.8l592-464a24 24 0 0 0-29.6-37.8"
      />
    </svg>
  </SvgIcon>
);
