export function formattedDate(date: Date) {
  return date.toLocaleDateString("fr");
}

export function formattedHour(date: Date) {
  return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
}

export function toLocalDate(date: Date) {
  return `${formattedDate(date)} à ${formattedHour(date)}`;
}
