import React from "react";

import { Container, Stack, Typography } from "@mui/material";

import { VerticalCentered } from "~components/VerticalCentered";
import { Footer } from "~pages/auth/Footer";

export const Fallback = () => (
  <Container sx={{ textAlign: "center" }} maxWidth="xs">
    <VerticalCentered>
      <Stack>
        <Typography variant="h6">
          Bienvenue sur le service Evalucar !
        </Typography>
        <Typography>
          Pour vous connecter, vous devez cliquer sur le lien présent dans
          l’invitation reçue par email et/ou sms.
        </Typography>
        <Typography>
          En cas de difficulté, n’hésitez pas à contacter votre expert.
        </Typography>
      </Stack>
    </VerticalCentered>
    <Footer />
  </Container>
);
