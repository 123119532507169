import { SvgIconProps, styled } from "@mui/material";
import { PaletteProps, palette } from "@mui/system";

export const Path = styled("path")<PaletteProps>(palette);

export interface ColorIconProps {
  primaryColor?: string;
  secondaryColor?: string;
  size?: SvgIconProps["fontSize"];
}

export { ReactComponent as Address } from "./Address.svg";
export * from "./CarContract";
export * from "./CarCrash";
export * from "./CarKeys";
export * from "./CarRepair";
export * from "./CarValue";
export * from "./CG";
export * from "./DocumentSignature";
export * from "./Warning";
