import React, { FC, PropsWithChildren, useEffect } from "react";

import {
  Container,
  ContainerProps,
  Paper,
  Stack,
  Toolbar,
} from "@mui/material";
import { Theme } from "@mui/material/styles/createTheme";
import { SystemProps } from "@mui/system";
import { ResponsiveStyleValue } from "@mui/system/styleFunctionSx/styleFunctionSx";

import { Header, Props as HeaderProps } from "~components/Header";
import { VerticalCentered } from "~components/VerticalCentered";
import { useMediaDesktop } from "~hooks/useMediaDesktop";
import { StepStatus } from "~models/StepStatus";
import { esignService } from "~services/esignService";

interface Props {
  title: string;
  maxWidth?: ContainerProps["maxWidth"];
  textAlign?: SystemProps<Theme>["textAlign"];
  alignItems?: SystemProps<Theme>["alignItems"];
  children?: React.ReactNode;
  verticalCentered?: ResponsiveStyleValue<boolean>;
  stepStatus: StepStatus;
}

export const Template: FC<Props & HeaderProps> = ({
  children,
  maxWidth,
  textAlign = "justify",
  alignItems = "center",
  verticalCentered = false,
  stepStatus,
  ...props
}) => {
  const Wrapper = verticalCentered ? VerticalCentered : React.Fragment;
  useEffect(() => {
    esignService.notifyStepStatus(stepStatus);
  }, [stepStatus]);
  return (
    <Container maxWidth={maxWidth}>
      <Header {...props} />
      <Wrapper>
        <Toolbar sx={{ mb: 4 }} />
        <DesktopWrapper>
          <Stack textAlign={textAlign} alignItems={alignItems}>
            {children}
          </Stack>
        </DesktopWrapper>
      </Wrapper>
    </Container>
  );
};

const DesktopWrapper: FC<PropsWithChildren> = ({ children }) => {
  const desktop = useMediaDesktop();
  return desktop ? (
    <Paper sx={{ padding: 4 }}>{children}</Paper>
  ) : (
    <>{children}</>
  );
};
