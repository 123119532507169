import { DependencyList, useCallback, useRef } from "react";

export function useDebouncedCallback<T extends (...args: any) => any>(
  callback: T,
  delay: number,
  dependencies: DependencyList
) {
  const timeout = useRef<NodeJS.Timeout>();

  return useCallback(
    (...args: Parameters<T>) => {
      clearTimeout(timeout.current);
      const [,] = dependencies;
      timeout.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay, dependencies]
  );
}
