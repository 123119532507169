import React, { FC } from "react";

import { Button, CardMedia, DialogActions, DialogContent } from "@mui/material";

import { BottomSheet } from "~components/BottomSheet";

const youtubeLink = (variant: string) => {
  switch (variant) {
    case "CrossOut":
      return "https://www.youtube.com/embed/TYqkeAm9Ntw?si=kvEzpoGRExvFYSq1?autoplay=1";
    case "Write":
      return "https://www.youtube.com/embed/x_oFJXmzAKY?si=lBP6qaBdgg319QrK?autoplay=1";
    case "Signed":
      return "https://www.youtube.com/embed/m_FiLyA-GiU?si=NPXXegA5eUOvkZsH?autoplay=1";
    case "CoSigned":
      return "https://www.youtube.com/embed/kOWcv3ioxbc?si=nVmRP5iSz40amGzV?autoplay=1";
    case "Stamped":
      return "https://www.youtube.com/embed/1LkL8hb7Mr8?si=vdlEu8ZuH6hS6Kod?autoplay=1";
  }
};

export const CGTuto: FC<{
  open: boolean;
  close: () => void;
  variant: string;
}> = ({ open, close, variant }) => {
  const srcVideo = youtubeLink(variant);

  return (
    <BottomSheet open={open} onClose={close}>
      <DialogContent>
        <CardMedia
          sx={{ height: "50vh", width: "100%" }}
          component="iframe"
          src={srcVideo}
          title="Evalucar esign"
          allowFullScreen
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={close}>
          Fermer
        </Button>
      </DialogActions>
    </BottomSheet>
  );
};
