import { useMemo } from "react";

import { useNavigate } from "react-router-dom";

import { SignatureSummary } from "~models/SignatureSummary";
import { Loading } from "~pages/Loading";
import { esignService } from "~services/esignService";
import { showCGInstruction, userSigned } from "~utils/signatureRequest";

import { useSignatureSummary } from "../../layouts/EsignLayout";

const PromiseTimeout = <T,>(f: () => T | PromiseLike<T>, ms: number) =>
  new Promise<T>((resolve) => setTimeout(() => resolve(f()), ms));
const _fetchSummary: (o: SignatureSummary) => Promise<SignatureSummary> = (
  signatureSummary: SignatureSummary
) => {
  const status =
    signatureSummary.signatureData.currentUser.activeWorkflowStatus;
  if (
    status?.status === "pending" ||
    status?.status === "processing" ||
    status?.status === undefined
  ) {
    return PromiseTimeout(esignService.getSignatureSummary, 3000).then(
      _fetchSummary
    );
  } else {
    return Promise.resolve(signatureSummary);
  }
};
export const Posthoc = () => {
  const [signatureSummary, setSignatureSummary] = useSignatureSummary();
  const updatedSummary = useMemo(
    () =>
      _fetchSummary(signatureSummary)
        .then(setSignatureSummary)
        .then(() => PromiseTimeout(() => {}, 2000)),
    [setSignatureSummary, signatureSummary]
  );
  const navigate = useNavigate();
  const signed = userSigned(signatureSummary);

  return (
    <Loading
      completedCG={false}
      signed={signed}
      hasCG={showCGInstruction(signatureSummary)}
      action={updatedSummary}
      onLoaded={() => navigate("/")}
    />
  );
};
