import React, { FC } from "react";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  TextFieldProps,
} from "@mui/material";

export const SingleCheckbox: FC<
  Omit<FormControlLabelProps, "control"> &
    Pick<TextFieldProps, "helperText" | "error" | "required">
> = ({ helperText, error, required, ...FormControlLabelProps }) => {
  return (
    <FormControl error={error} required={required}>
      <FormControlLabel control={<Checkbox />} {...FormControlLabelProps} />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
