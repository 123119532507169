import React, { FC } from "react";

import { FormControl, MenuItem, TextField, Typography } from "@mui/material";

import { useMuiForm } from "~hooks/useMuiForm";

import { RecapStepProps } from "./index";

export const RenterSelector: FC<RecapStepProps> = ({ signatureSummary }) => {
  const { register, watch } = useMuiForm();

  return (
    <FormControl fullWidth>
      <Typography fontWeight="bold" my={2}>
        Votre Signature
      </Typography>
      <TextField
        label="Selectionnez votre nom"
        select
        value={watch("legalPersonInformation") ?? ""}
        {...register("legalPersonInformation")}
      >
        {signatureSummary.renterSummary?.legalPersonInformation.map(
          (info, i) => (
            // @ts-ignore
            <MenuItem value={info} key={i}>
              {info.lastName} {info.firstName}
            </MenuItem>
          )
        )}
      </TextField>
    </FormControl>
  );
};
