import React, { FC } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { Card, CardHeader, Stack, Typography } from "@mui/material";

import { NextButton } from "~components/NextButton";
import { Template } from "~components/Template";
import { EndSVG } from "~illustrations";
import { SummaryStep } from "~models/StepStatus";
import { User } from "~models/User";
import { PosthocStep } from "~pages/esign/posthoc";
import { formattedDate } from "~utils/date";
import { ChoiceLabelFor } from "~utils/wording";

const signedAt = (user: User) =>
  user.signature?.signedAt ? formattedDate(user.signature?.signedAt) : "";

export const Summary: PosthocStep = ({ back, next, signatureSummary }) => {
  const { signatureData } = signatureSummary;
  const { currentUser: holder, users } = signatureData;
  const sorted = [...users].sort((a, b) => a.order - b.order);

  return (
    <Template
      back={back}
      title="Statut de mon dossier"
      stepStatus={SummaryStep}
    >
      <EndSVG />
      <Typography fontWeight="bold">
        Nous sommes maintenant en attente de la signature du cotitulaire
      </Typography>
      <Typography>
        Votre signature a bien été prise en compte. Un email récapitulatif a été
        envoyé à {holder.email}. Votre cotitulaire vient de recevoir notre
        invitation.
      </Typography>
      <Stack width="100%" textAlign="center">
        {sorted.map((user) => (
          <Card key={user.order} variant="soft">
            <CardHeader
              title={`${user.firstName || ""} ${user.lastName}`}
              subheader={<SignatureStatus holder={holder} user={user} />}
            />
          </Card>
        ))}
      </Stack>
      {next && <NextButton onClick={next}>Suivant</NextButton>}
    </Template>
  );
};

const SignatureStatus: FC<{ user: User; holder: User }> = ({
  user,
  holder,
}) => (
  <ConditionallyShow
    user={user}
    holder={holder}
    whenAgreed={
      <SignatureStatusInfo
        icon={<CheckCircleIcon color="success" />}
        status={`Signé le ${signedAt(user)}`}
      />
    }
    whenDisagreed={
      <SignatureStatusInfo
        icon={<ErrorIcon color="error" />}
        status={`Ne souhaite pas ${ChoiceLabelFor(
          holder
        )} le véhicule mais souhaite le ${ChoiceLabelFor(
          user
        )}. Choix enregistré le ${signedAt(user)}`}
      />
    }
    whenPending={
      <SignatureStatusInfo
        icon={<HourglassTopIcon color="warning" />}
        status="En attente de signature"
      />
    }
    whenRefused={
      <SignatureStatusInfo
        icon={<CancelIcon color="error" />}
        status="Refus de signer les documents"
      />
    }
  />
);

interface SignatureStatusInfoProps {
  icon: React.ReactNode;
  status: string;
}

const SignatureStatusInfo: FC<SignatureStatusInfoProps> = ({
  icon,
  status,
}) => (
  <Stack direction="row" display="inline-flex" spacing={2} mt={2}>
    {icon}
    <Typography>{status}</Typography>
  </Stack>
);

interface ConditonallyProps {
  user: User;
  holder: User;
  whenDisagreed: React.ReactNode;
  whenRefused: React.ReactNode;
  whenAgreed: React.ReactNode;
  whenPending: React.ReactNode;
}

const ConditionallyShow: FC<ConditonallyProps> = (props) => {
  const { user, holder } = props;
  if (user.activeWorkflowStatus?.status === "refused") {
    return <>{props.whenRefused}</>;
  } else if (!user.signature?.choice) {
    return <>{props.whenPending}</>;
  } else if (user.signature?.choice === holder?.signature?.choice) {
    return <>{props.whenAgreed}</>;
  } else {
    return <>{props.whenDisagreed}</>;
  }
};
