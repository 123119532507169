import React, { FC } from "react";

import { SvgIcon } from "@mui/material";

import { ColorIconProps, Path } from "~icons/index";

export const DocumentSignature: FC<ColorIconProps> = ({
  primaryColor,
  secondaryColor = "primary.dark",
  size,
}) => (
  <SvgIcon inheritViewBox fontSize={size}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 766 512">
      <Path
        color={secondaryColor}
        d="M712 128c-11 0-21 4-29 12l-29 29 71 71 29-29c16-16 16-41 0-57l-14-14c-8-8-18-12-28-12Zm-81 64L502 321c-4 4-7 9-8 15l-15 60c-2 5 0 11 4 15s9 6 15 4l60-15c6-1 11-4 15-8l129-129Z"
      />
      <Path
        color={primaryColor}
        d="M254 0c-35 0-64 29-64 64v384c0 35 29 64 64 64h256c35 0 64-29 64-64v-19l-8 2-60 15a56 56 0 0 1-12 2h-64c-6 0-12-3-14-9l-9-17c-2-4-5-6-9-6s-7 2-9 6l-8 17a16 16 0 0 1-30-2l-17-56-10 33c-6 20-25 34-46 34h-8c-9 0-16-7-16-16s7-16 16-16h8c7 0 14-5 16-11l14-50a27 27 0 0 1 52 0l11 39a42 42 0 0 1 64 14l5 8h9c-3-9-4-18-2-28l15-60c3-11 9-21 17-30l95-94v-44H446c-18 0-32-14-32-32V0Zm192 0v128h128z"
      />
    </svg>
  </SvgIcon>
);
