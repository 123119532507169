import "@mui/lab/themeAugmentation";
import { experimental_extendTheme as extendTheme } from "@mui/material/styles";

import { ThemeColors } from "~models/ThemeColors";

import breakpoints from "./breakpoints";
import components from "./components";
import palette from "./palette";
import shadows from "./shadows";
import typography from "./typography";

const theme = (themeColor: ThemeColors) =>
  extendTheme(
    {
      spacing: 4,
      breakpoints,
      components,
      typography,
      shadows,
      colorSchemes: palette(themeColor),
    },
    {
      palette: {
        conclusion: {
          totalCarValue: {
            color: "#C90808",
            contrastText: "#FFFFFF",
          },
          repairAmount: {
            color: "#0CBC8B",
            contrastText: "#FFFFFF",
          },
          carValue: {
            color: themeColor.dark,
            contrastText: "#FFFFFF",
          },
        },
      },
    }
  );

export default theme;
