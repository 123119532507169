import { FC, useCallback, useState } from "react";

import { FileWithData } from "~utils/files";

import { PosthocProps, PosthocStep } from "..";
import { AddCGPhoto } from "../instructionCG/AddCGPhoto";
import { InvalidCG } from "./InvalidCG";
import { ValidateCG } from "./ValidateCG";
import { VerifyCG } from "./VerifyCG";

interface CheckerCGComponentProps extends PosthocProps {
  cg: FileWithData;
  onErrorRetry: (hasConsigneError: boolean) => void;
}

const CheckerCGComponent: FC<CheckerCGComponentProps> = ({
  signatureSummary,
  next,
  cg,
  onErrorRetry,
  back,
}) => {
  const { checker } = signatureSummary;
  const hasVerified =
    checker && (checker.totalTries >= 2 || !checker.lastTryContainsErrors);
  const isAlreadyValid = hasVerified && !checker.lastTryContainsErrors;
  const [isverifying, setIsVerifying] = useState<boolean>(!hasVerified);
  const [isValid, setIsValid] = useState<boolean>(isAlreadyValid || false);

  const onValid = useCallback(() => {
    setIsVerifying(false);
    setIsValid(true);
  }, []);

  const onInvalid = useCallback(() => {
    setIsVerifying(false);
    setIsValid(false);
  }, []);

  if (isverifying)
    return (
      <VerifyCG
        cg={cg}
        onValid={onValid}
        onInvalid={onInvalid}
        onErrorRetry={onErrorRetry}
        back={back}
        signatureSummary={signatureSummary}
      />
    );

  return isValid ? (
    <ValidateCG next={next} signatureSummary={signatureSummary} />
  ) : (
    <InvalidCG />
  );
};

interface CheckerCGProps extends PosthocProps {
  restart: () => void;
}

const CheckerCG: FC<CheckerCGProps> = ({
  next,
  back,
  signatureSummary,
  restart,
}) => {
  const { checker } = signatureSummary;
  const [cg, setCG] = useState<FileWithData>();
  const [isPhotoValidated, setIsPhotoValidated] = useState(false);

  const setCGPhoto = useCallback((file: FileWithData) => {
    setCG(file);
    setIsPhotoValidated(true);
  }, []);

  const handleCheckerError = useCallback(
    (hasConsigneError: boolean) => {
      if (hasConsigneError) restart();
      else setIsPhotoValidated(false);
    },
    [restart]
  );

  if (checker) {
    const { lastTryContainsErrors, totalTries } = checker;
    if (totalTries > 1 && lastTryContainsErrors) return <InvalidCG />;
    if (totalTries > 0 && !lastTryContainsErrors)
      return <ValidateCG next={next} signatureSummary={signatureSummary} />;
  }

  return isPhotoValidated && cg ? (
    <CheckerCGComponent
      cg={cg}
      next={next}
      signatureSummary={signatureSummary}
      onErrorRetry={handleCheckerError}
      back={back}
    />
  ) : (
    <AddCGPhoto
      onValidate={setCGPhoto}
      signatureSummary={signatureSummary}
      back={back}
      defaultCg={cg}
      next={next}
    />
  );
};

export const getCheckerCG = (restart: () => void) => {
  const CheckerCGCmp: PosthocStep = (props) => (
    <CheckerCG restart={restart} {...props} />
  );

  return CheckerCGCmp;
};
