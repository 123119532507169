import React, { FC, MouseEventHandler } from "react";

import { Collapse, Link, Stack, Typography } from "@mui/material";

import { SingleCheckbox } from "~components/SingleCheckbox";
import { useMuiForm } from "~hooks/useMuiForm";
import { useToggle } from "~hooks/useToggle";
import { ConditionsSchema } from "~models/Schemas";
import { StepProps } from "~pages/esign";
import { RecapStep } from "~pages/esign/recap/index";

import ConditionsText from "./ConditionsText";

interface Props {
  label: string;
  info?: string;
}

const Condition: FC<Props & { index: number }> = ({ label, info, index }) => {
  const [open, toggle] = useToggle();
  const { register, watch } = useMuiForm("conditions");
  const showMore: MouseEventHandler<any> = (e) => {
    e.preventDefault();
    toggle();
  };
  return (
    <SingleCheckbox
      label={
        <>
          <Typography>{label}</Typography>
          {info && (
            <>
              <Link onClick={showMore} color="primary">
                En savoir plus
              </Link>
              <Collapse in={open}>{info}</Collapse>
            </>
          )}
        </>
      }
      {...register(index.toString())}
      checked={!!watch(index.toString())}
    />
  );
};
const Conditions: FC<{ conditions: Props[] }> = ({ conditions }) => (
  <Stack spacing={2}>
    {conditions.map((condition, i) => (
      <Condition {...condition} index={i} key={i} />
    ))}
  </Stack>
);

export const RepairConditions: RecapStep = Object.assign(
  ({ signatureSummary }: StepProps<"Recap">) => {
    const data = signatureSummary.applicationData;
    const label = data.recoverableVAT ? "HT" : "TTC";
    return (
      <Conditions
        conditions={
          ConditionsText["repair"]({
            insurance: data.insurance,
            replacementValue: data[`replaceValue${label}`],
            replacementValueLabel: label,
          })[data.sinisterType]
        }
      />
    );
  },
  {
    title: "Conditions de réparation",
    schema: ConditionsSchema,
  }
);
export const KeepConditions: RecapStep = Object.assign(
  ({ signatureSummary }: StepProps<"Recap">) => {
    const data = signatureSummary.applicationData;
    const label = data.recoverableVAT ? "HT" : "TTC";
    const residualValue = data[`residualValue${label}`];
    const value =
      residualValue && residualValue !== "0"
        ? `établie à ${residualValue}€ ${label} `
        : "";
    return (
      <Conditions
        conditions={
          ConditionsText["keep"]({
            insurance: data.insurance,
            residualValue: value,
          })[data.sinisterType]
        }
      />
    );
  },
  {
    title: "Conditions de conservation",
    schema: ConditionsSchema,
  }
);
export const SellConditions: RecapStep = Object.assign(
  ({ signatureSummary }: StepProps<"Recap">) => {
    const data = signatureSummary.applicationData;
    const label = data.recoverableVAT ? "HT" : "TTC";
    const residualValue = data[`residualValue${label}`] || "";
    return (
      <Conditions
        conditions={
          ConditionsText["sell"]({ residualValue, label })[data.sinisterType]
        }
      />
    );
  },
  {
    title: "Conditions de cession",
    schema: ConditionsSchema,
  }
);
