import React, { FC } from "react";

import {
  Dialog,
  DialogProps,
  Slide,
  SlideProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export const BottomSheet: FC<DialogProps> = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      {...props}
      fullScreen={fullScreen}
      {...(fullScreen ? { TransitionComponent: Transition } : {})}
    />
  );
};
