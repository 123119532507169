import React, { FC, useMemo } from "react";

import { Button, Link, Typography } from "@mui/material";

import { ButtonGroup } from "~components/ButtonGroup";
import { Template } from "~components/Template";
import { useMediaDesktop } from "~hooks/useMediaDesktop";
import { StepStatus } from "~models/StepStatus";
import { StepProps } from "~pages/esign/index";
import { ChoiceLabel } from "~utils/wording";

export const Disagreement: FC<StepProps<"Signature">> = ({ step, setStep }) => {
  const desktop = useMediaDesktop();
  const stepStatus = useMemo<StepStatus>(
    () => ({ type: "Disagreed", choice: step.choice }),
    [step.choice]
  );
  return (
    <Template
      title="Votre décision"
      back={() => setStep({ value: "Choice" })}
      maxWidth="xs"
      verticalCentered={desktop}
      stepStatus={stepStatus}
    >
      <Typography fontWeight="bold">
        Le dossier ne peut pas être traité en l’état.
      </Typography>
      <Typography>
        Contrairement au choix fait par le titulaire, vous avez décidé de{" "}
        {ChoiceLabel(step.choice)} votre véhicule.
      </Typography>
      <Typography>
        L'expert automobile en charge de votre sinistre et le titulaire ont été
        informés de cette situation.
      </Typography>
      <Typography>
        Si vous le souhaitez, vous pouvez modifier votre choix en cliquant sur
        le bouton ci-dessous.
      </Typography>

      <ButtonGroup>
        <Button
          onClick={() => setStep({ value: "Choice" })}
          variant="outlined"
          fullWidth
        >
          Modifier mon choix
        </Button>
        <Link onClick={window.close} textAlign="center">
          Fermer la page et revenir plus tard
        </Link>
      </ButtonGroup>
    </Template>
  );
};
