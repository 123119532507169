import { CheckerResponse } from "~models/CheckerResponse";
import { CompanyInfo } from "~models/CompanyInfo";
import { EsignRating } from "~models/EsignRating";
import { SignatureLink } from "~models/SignatureLink";
import { SignatureSummary } from "~models/SignatureSummary";
import { StepStatus } from "~models/StepStatus";
import { UpdateRequest } from "~models/UpdateRequest";
import { UserChoice } from "~models/UserChoice";
import { UserChoiceOptions } from "~models/UserChoiceOptions";
import { UserLoginInfo } from "~models/UserLoginInfo";
import { ValidationUrlInfo } from "~models/ValidationUrlInfo";
import axios, { getData } from "~utils/axios";
import { FileWithData } from "~utils/files";

export function getLoginInfo(token: string): Promise<UserLoginInfo> {
  return axios.get<UserLoginInfo>(`api/v1/login/${token}`).then(getData);
}

export function createSignature(
  choice: UserChoice,
  data: UserChoiceOptions
): Promise<SignatureLink> {
  return axios
    .post<SignatureLink>(`api/v1/signature?choice=${choice}`, data)
    .then(getData)
    .catch((error) => {
      if (error.response?.status === 409) {
        return "disagreement";
      } else {
        return Promise.reject(error);
      }
    });
}

function getSignatureSummary(): Promise<SignatureSummary> {
  return axios.get<SignatureSummary>(`api/v1/signature/summary`).then(getData);
}

function submitRating(rating: EsignRating) {
  return axios.put(`api/v1/rating`, rating).then(getData);
}

function submitDocument(document: FileWithData): Promise<CheckerResponse> {
  return axios
    .post(`api/v1/signature/document`, document.file, {
      headers: {
        "Content-Type": document.file.type,
      },
    })
    .then(getData);
}

function notifyStepStatus({ type, ...info }: StepStatus) {
  return axios
    .post(`api/v1/signature/step`, { stepStatus: type, info })
    .then(() => {});
}

function getCompanyInfo(siren: string): Promise<CompanyInfo> {
  return axios.get(`api/v1/company/${siren}`).then(getData);
}

function validate(loginUrl: string): Promise<ValidationUrlInfo> {
  return axios.put(`api/v1/signature/${loginUrl}/validate`).then(getData);
}

function sendMessage(loginUrl: string, message: string): Promise<any> {
  return axios
    .post(`api/v1/signature/${loginUrl}/contact`, { message })
    .then(getData);
}

function updateInfo(updateRequest: UpdateRequest): Promise<any> {
  return axios.post(`api/v1/verify`, updateRequest).then(getData);
}

export const esignService = {
  createSignature,
  getSignatureSummary,
  submitRating,
  submitDocument,
  getLoginInfo,
  notifyStepStatus,
  getCompanyInfo,
  validate,
  sendMessage,
  updateInfo,
};
