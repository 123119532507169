import React, { FC } from "react";

import { Box, Grid, Stack, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material/styles/createTheme";
import { SystemProps } from "@mui/system";

interface Props {
  illustration: React.ReactNode;
  illustrationHeight: SystemProps<Theme>["height"];
  children?: React.ReactNode;
}

export const IllustratedContent: FC<Props> = ({
  illustration,
  illustrationHeight,
  children,
}) => {
  const smDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <Box>
      <Grid container alignItems="center" spacing={6}>
        <Grid
          item
          height={illustrationHeight}
          xs={12}
          sm={children ? 6 : undefined}
          textAlign="center"
        >
          {illustration}
        </Grid>
        {children && (
          <Grid item xs={12} sm={6} textAlign={smDown ? "center" : "left"}>
            <Stack>{children}</Stack>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
