import React, { FC } from "react";

import { Outlet, useLoaderData } from "react-router-dom";

import { Container } from "@mui/material";
import { CssBaseline } from "@mui/material";
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";

import { UserLoginInfo } from "~models/UserLoginInfo";
import { Footer } from "~pages/auth/Footer";

import theme from "../theme";
import { ReverseColor } from "../theme/ReverseColor";

interface Props {
  children?: React.ReactNode;
}

export const AuthLayout: FC<Props> = ({ children }) => {
  const userLoginInfo = useLoaderData() as UserLoginInfo;
  return (
    <CssVarsProvider theme={theme(userLoginInfo.theme)}>
      <CssBaseline />
      <Container sx={{ textAlign: "center" }}>
        <ReverseColor />
        {children}
        <Outlet />
        <Footer />
      </Container>
    </CssVarsProvider>
  );
};
