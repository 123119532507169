import React from "react";

import { GlobalStyles } from "@mui/material";

export const ReverseColor = () => (
  <GlobalStyles
    styles={(theme) => ({
      body: {
        transition: "background-color 500ms ease-in-out",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    })}
  />
);
