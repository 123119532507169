import * as React from "react";

import { Check, Visibility } from "@mui/icons-material";
import { Button, CardMedia, Stack, Typography } from "@mui/material";

import { CircledIcon } from "~components/CircledIcon";
import { IllustratedContent } from "~components/IllustratedContent";
import { CheckerError } from "~models/CheckerResponse";
import { FileWithData } from "~utils/files";

const ErrorToLabel: Record<string, string> = {
  signatureDate: "La date de signature ne correspond pas",
  signatureCount: "Il y a une erreur sur le nombre de signatures",
  matriculation: "L'immatriculation ne correspond pas au dossier",
  formulaNumber: "Le numéro de formule ne correspond pas au dossier",
  cUn: "Il y a une erreur au niveau du champ c1 de la CG",
  cQuatreUn: "Il y a une erreur au niveau du champ c4.1 de la CG",
  address: "L'adresse marquée sur la CG ne correspond pas au dossier",
  mention: 'La mention "CEDE LE" est absente.',
};

interface ErrorDescriptionProps extends React.PropsWithChildren {
  description: string;
}

const ErrorDescription: React.FC<ErrorDescriptionProps> = ({
  children,
  description,
}) => {
  return (
    <Stack direction="row" alignItems="center" width="100%" textAlign="left">
      <CircledIcon bgcolor="secondary.dark">{children}</CircledIcon>
      <Typography color="red" fontSize="14px">
        {description}
      </Typography>
    </Stack>
  );
};

interface KOErrorProps {
  errors: Array<CheckerError>;
  hasConsigneError: boolean;
  cg: FileWithData;
  onRetry: () => void;
}

export const ErrorCG: React.FC<KOErrorProps> = ({
  errors,
  cg,
  onRetry,
  hasConsigneError,
}) => {
  return (
    <Stack direction={"column"}>
      <IllustratedContent
        illustrationHeight="50vh"
        illustration={
          cg.file.type === "application/pdf" ? (
            <embed
              src={`${cg.data}#toolbar=0&navpanes=0&scrollbar=0`}
              width="100%"
              height="100%"
            ></embed>
          ) : (
            <CardMedia
              image={cg.data}
              sx={{ backgroundSize: "contain", height: "100%" }}
            />
          )
        }
      />
      {errors.map(({ details }) => (
        <ErrorDescription key={details} description={ErrorToLabel[details]}>
          <Visibility color="error" />
        </ErrorDescription>
      ))}
      {hasConsigneError && (
        <ErrorDescription description="Merci de respecter les consignes et de réessayer">
          <Check color="error" />
        </ErrorDescription>
      )}
      <Button variant="outlined" onClick={onRetry}>
        Essayer à nouveau
      </Button>
    </Stack>
  );
};
