import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { Button, Stack, Typography } from "@mui/material";

import { IllustratedContent } from "~components/IllustratedContent";
import { NextButton } from "~components/NextButton";
import { SingleCheckbox } from "~components/SingleCheckbox";
import { Template } from "~components/Template";
import { useMediaDesktop } from "~hooks/useMediaDesktop";
import { useToggle } from "~hooks/useToggle";
import { CG, CGVariant } from "~illustrations/CG";
import { StepStatus } from "~models/StepStatus";
import { PosthocProps } from "~pages/esign/posthoc";
import { CGTuto } from "~pages/esign/posthoc/instructionCG/CGTuto";

interface Props {
  variant: CGVariant;
  children: React.ReactNode;
  confirmLabel: string;
}

const instructionStep = (variant: CGVariant, hasMultiSigners: boolean) => {
  switch (variant) {
    case "CrossOut":
      return 0;
    case "Write":
      return 1;
    case "Signed":
      return 2;
    case "CoSigned":
      return 3;
    case "Stamped":
      return hasMultiSigners ? 4 : 3;
  }
};
const instructionProgress = (variant: CGVariant, hasMultiSigners: boolean) =>
  instructionStep(variant, hasMultiSigners) * 2;

export const Instruction: FC<PosthocProps & Props> = ({
  variant,
  signatureSummary,
  children,
  back,
  next,
  confirmLabel,
}) => {
  const desktop = useMediaDesktop();
  const [showTuto, toggleTuto] = useToggle(false);
  const signedAt = signatureSummary.signatureData.signatureDate;
  const hasMultiSigners = signatureSummary.signatureData.users.length > 1;
  const stepStatus = useMemo<StepStatus>(
    () => ({
      type: "CGInstruction",
      step: instructionStep(variant, hasMultiSigners),
    }),
    [hasMultiSigners, variant]
  );
  const [showError, setShowError] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState<boolean>(false);

  const handleNext = useCallback(() => {
    if (confirmed) next?.();
    else setShowError(true);
  }, [confirmed, next]);

  const handleConfirmedChange = useCallback((_: any, checked: boolean) => {
    setConfirmed(checked);
    if (checked) setShowError(false);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Template
      back={back}
      title="Consignes Carte Grise"
      progress={60 + instructionProgress(variant, hasMultiSigners)}
      verticalCentered={desktop}
      alignItems="stretch"
      stepStatus={stepStatus}
    >
      <IllustratedContent
        illustrationHeight="50vh"
        illustration={
          <CG
            variant={variant}
            height="100%"
            signedDate={signedAt}
            hasMultiSigners={hasMultiSigners}
          />
        }
      >
        {children}
        <Stack spacing={4} alignItems="center" textAlign="center">
          <Button variant="outlined" fullWidth onClick={toggleTuto}>
            Aide vidéo
          </Button>

          <SingleCheckbox
            label={confirmLabel}
            checked={confirmed}
            onChange={handleConfirmedChange}
          />

          {showError && (
            <Typography textAlign="center" color={"red"} fontSize="14px">
              Merci de confirmer pour pouvoir passer à l'étape suivante
            </Typography>
          )}
          <NextButton onClick={handleNext}>Suivant</NextButton>
        </Stack>
      </IllustratedContent>
      <CGTuto variant={variant} open={showTuto} close={toggleTuto} />
    </Template>
  );
};
