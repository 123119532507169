import React from "react";

import { IMaskMixin } from "react-imask";

import { TextField, TextFieldProps } from "@mui/material";

import IMask from "imask";

const IMaskTextField = IMaskMixin<
  IMask.AnyMaskedOptions,
  boolean,
  string,
  HTMLInputElement,
  Omit<TextFieldProps, "onChange"> & {
    onChange?: (event: any) => void;
  }
>(({ inputRef, ...props }) => <TextField {...props} inputRef={inputRef} />);

export const MaskedTextField: typeof IMaskTextField = ({
  onChange,
  ...props
}) => (
  <IMaskTextField
    onAccept={(value) =>
      onChange &&
      onChange({
        target: { name: props.name || "", value },
      })
    }
    {...props}
  />
);
