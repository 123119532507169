import { Typography } from "@mui/material";

import { IllustratedContent } from "~components/IllustratedContent";
import { NextButton } from "~components/NextButton";
import { Template } from "~components/Template";
import { useMediaDesktop } from "~hooks/useMediaDesktop";
import { ValidateCGSVG } from "~illustrations";
import { ModifyCGStep } from "~models/StepStatus";
import { PosthocStep } from "~pages/esign/posthoc";

export const ValidateCG: PosthocStep = ({ next, signatureSummary }) => {
  const desktop = useMediaDesktop();
  const { mandatoryCGCheck } = signatureSummary.configData;

  return (
    <Template
      title={`Document ${mandatoryCGCheck ? "validé" : "envoyé"}`}
      verticalCentered={desktop}
      progress={80}
      stepStatus={ModifyCGStep}
    >
      <IllustratedContent
        illustrationHeight={{ xs: "25vh", md: "50vmin" }}
        illustration={
          <ValidateCGSVG style={{ height: "100%", width: "100%" }} />
        }
      >
        <Typography
          sx={{
            fontWeight: "bold",
          }}
        >
          {`Votre document ${
            mandatoryCGCheck ? "est validé " : "a été envoyé"
          }!`}
        </Typography>
        <Typography> Vous pouvez poursuivre votre dossier. </Typography>
        <NextButton onClick={next}>Suivant</NextButton>
      </IllustratedContent>
    </Template>
  );
};
