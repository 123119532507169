import React, { FC } from "react";

import { SvgIcon } from "@mui/material";

import { ColorIconProps, Path } from "~icons/index";

export const Warning: FC<ColorIconProps> = ({
  primaryColor,
  secondaryColor = "primary.dark",
  size,
}) => (
  <SvgIcon inheritViewBox fontSize={size}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 54">
      <Path
        color={secondaryColor}
        d="M61.1 46.4 35.5 2.5A5.1 5.1 0 0 0 31 0c-1.8 0-3.3.8-4.5 2.5L1 46.5c-1 1.7-1 3.4 0 5 .8 1.6 2.3 2.4 4.4 2.5h51.4c2 0 3.5-1 4.4-2.5 1-1.6 1-3.3 0-5Zm-33-30c.2-1.8 1.1-2.7 2.9-2.9 1.8.2 2.7 1.1 2.9 2.9v15.4c-.2 1.8-1 2.8-2.8 3-.8 0-1.5-.4-2-1-.7-.5-1-1.2-1-2V16.4ZM31 46.3c-1 0-2-.4-2.7-1.1-.7-.7-1-1.6-1-2.7 0-1 .3-2 1-2.7a4 4 0 0 1 2.7-1.1c1 0 2 .4 2.7 1a4 4 0 0 1 1 2.8c0 1-.3 2-1 2.7a4 4 0 0 1-2.7 1Z"
      />
      <Path
        color={primaryColor}
        d="M28.1 16.4c.2-1.8 1.1-2.7 2.9-2.9 1.8.2 2.7 1.1 2.9 2.9v15.4c-.2 1.8-1 2.8-2.8 3-.8 0-1.5-.4-2-1-.7-.5-1-1.2-1-2V16.4ZM31 46.3c-1 0-2-.4-2.7-1.1-.7-.7-1-1.6-1-2.7 0-1 .3-2 1-2.7a4 4 0 0 1 2.7-1.1c1 0 2 .4 2.7 1a4 4 0 0 1 1 2.8c0 1-.3 2-1 2.7a4 4 0 0 1-2.7 1Z"
      />
    </svg>
  </SvgIcon>
);
