import { useEffect } from "react";

import { StepProps } from "~pages/esign";
import { esignService } from "~services/esignService";

export const useRefreshSignatureUrl = ({
  step: { value, link, choice, options },
  setStep,
}: StepProps<"Signature">) => {
  useEffect(() => {
    const now = new Date();
    const ms =
      link !== "disagreement" && link.expiresAt
        ? link.expiresAt.getTime() - now.getTime()
        : undefined;
    const timeout = ms
      ? setTimeout(
          () =>
            esignService
              .createSignature(choice, options)
              .then((link) => setStep({ value, link, choice, options })),
          ms
        )
      : undefined;
    return () => clearTimeout(timeout);
  }, [choice, link, options, setStep, value]);
};
