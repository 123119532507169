import { Alert, Card, CardContent, Typography } from "@mui/material";

import { CheckerState } from "~models/CheckerState";
import { PosthocStep } from "~pages/esign/posthoc";
import { Instruction } from "~pages/esign/posthoc/instructionCG/Instruction";
import { SendCG } from "~pages/esign/posthoc/instructionCG/SendCG";
import { formattedDate } from "~utils/date";

import { getCheckerCG } from "../checkerCG";

const CrossOutCG: PosthocStep = (props) => (
  <Instruction
    {...props}
    variant="CrossOut"
    confirmLabel="J'ai barré ma carte grise"
  >
    <Card
      variant="outlined"
      sx={{
        mt: "8px !important",
        textAlign: "center",
      }}
    >
      <CardContent>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "1em !important",
            mt: "8px !important",
          }}
        >
          Pour commencer, barrez l'original de la carte grise tel qu'illustré
          sur l'image.
        </Typography>
      </CardContent>
    </Card>
    <Alert severity="error">
      Les photocopies{" "}
      <Typography
        variant="inherit"
        sx={{
          fontWeight: "bold",
          display: "inline",
        }}
      >
        ne sont pas acceptées.
      </Typography>
    </Alert>
  </Instruction>
);

const CgInfoCard = () => (
  <Alert severity="error">
    Assurez-vous de ne{" "}
    <Typography
      variant="inherit"
      sx={{
        fontWeight: "bold",
        display: "inline",
      }}
    >
      rien écrire d'autre
    </Typography>{" "}
    et de{" "}
    <Typography
      variant="inherit"
      sx={{
        fontWeight: "bold",
        display: "inline",
      }}
    >
      respecter la date
    </Typography>{" "}
    indiquée.
    <Typography
      variant="inherit"
      sx={{
        mt: "5px !important",
      }}
    >
      Dans le cas contraire, cela
    </Typography>{" "}
    <Typography
      variant="inherit"
      sx={{
        fontWeight: "bold",
        display: "inline",
      }}
    >
      retardera votre indemnisation
    </Typography>
    .
  </Alert>
);

const WriteCG: PosthocStep = (props) => (
  <Instruction
    {...props}
    variant="Write"
    confirmLabel={`J'ai écris la mention «CEDE LE ${formattedDate(
      props.signatureSummary.signatureData.signatureDate
    )}»`}
  >
    <Card
      variant="outlined"
      sx={{
        mt: "8px !important",
        mb: "8px !important",
        textAlign: "center",
      }}
    >
      <CardContent>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "1em !important",
            mt: "8px !important",
          }}
        >
          Ensuite, écrivez dans la zone en rouge :
        </Typography>
        <Typography
          color="primary"
          sx={{
            fontWeight: "bold",
            fontSize: "1.5em !important",
            mt: "5px !important",
            textAlign: "center",
          }}
        >
          CEDE LE{" "}
          {formattedDate(props.signatureSummary.signatureData.signatureDate)}
        </Typography>
      </CardContent>
    </Card>
    <CgInfoCard />
  </Instruction>
);

const SignCG: PosthocStep = (props) => (
  <Instruction
    {...props}
    variant="Signed"
    confirmLabel="J'ai signé la carte grise"
  >
    <Card
      variant="outlined"
      sx={{
        mt: "8px !important",
        mb: "8px !important",
        textAlign: "center",
      }}
    >
      <CardContent>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "1em !important",
            mt: "8px !important",
          }}
        >
          Ensuite, signez dans la zone en vert.
        </Typography>
      </CardContent>
    </Card>
  </Instruction>
);

const CoholderSignCG: PosthocStep = (props) => (
  <Instruction
    {...props}
    variant="CoSigned"
    confirmLabel="Le cotitulaire a signé"
  >
    <Card
      variant="outlined"
      sx={{
        mt: "8px !important",
        mb: "8px !important",
        textAlign: "center",
      }}
    >
      <CardContent>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "1em !important",
            mt: "8px !important",
          }}
        >
          Votre cotitulaire doit signer le certificat dans la zone en violet.
        </Typography>
      </CardContent>
    </Card>
  </Instruction>
);

const StampedCG: PosthocStep = (props) => (
  <Instruction
    {...props}
    variant="Stamped"
    confirmLabel="J'ai apposé mon tampon"
  >
    <Card
      variant="outlined"
      sx={{
        mt: "8px !important",
        mb: "8px !important",
        textAlign: "center",
      }}
    >
      <CardContent>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "1em !important",
            mt: "8px !important",
          }}
        >
          Apposez votre tampon tel qu'illustré
        </Typography>
      </CardContent>
    </Card>
  </Instruction>
);

interface InstructionCGParams {
  hasMultiSigners: boolean;
  holderIsLegalPerson: boolean;
  restart: () => void;
  checker?: CheckerState;
}

export const InstructionCG = ({
  hasMultiSigners,
  holderIsLegalPerson,
  restart,
  checker,
}: InstructionCGParams) => {
  if (checker && (checker.totalTries > 1 || !checker.lastTryContainsErrors))
    return [getCheckerCG(restart), SendCG];

  return [
    CrossOutCG,
    WriteCG,
    SignCG,
    ...(hasMultiSigners ? [CoholderSignCG] : []),
    ...(holderIsLegalPerson ? [StampedCG] : []),
    getCheckerCG(restart),
    SendCG,
  ];
};
