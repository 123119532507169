import React, { FC, useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  Alert,
  CircularProgress,
  Collapse,
  Container,
  Stack,
  Typography,
} from "@mui/material";

import MaskedLoginField from "~components/MaskedLoginField";
import { VerticalCentered } from "~components/VerticalCentered";
import { useAuth } from "~hooks/useAuth";
import { useDebouncedCallback } from "~hooks/useDebounce";
import { UserLoginInfo } from "~models/UserLoginInfo";
import { LoginCard } from "~pages/auth/LoginCard";

interface Props {
  loginUrl: string;
  info: UserLoginInfo;
}

export const Login: FC<Props> = ({ loginUrl, info }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [attempts, setAttempts] = useState<number>();
  const { login } = useAuth();

  const handleLogin = useDebouncedCallback(
    (matriculation: string) => {
      setLoading(true);
      login({ loginUrl, matriculation })
        .then((res) =>
          res.verify ? navigate("/verify", { state: res }) : navigate("/")
        )
        .catch(({ response: { data } }) => {
          setLoading(false);
          setAttempts(data.attemptLeft);
        });
    },
    500,
    []
  );

  return (
    <VerticalCentered>
      <Container maxWidth="xs">
        <Stack>
          <LoginCard info={info} />
          <Typography>
            Pour accéder à votre dossier, veuillez compléter votre
            immatriculation
          </Typography>
          <MaskedLoginField
            matriculation={info.matriculation}
            disabled={loading || attempts === 0}
            onAllFieldsFilled={handleLogin}
            hasExeption={attempts !== undefined}
            rejectExeption={() => setAttempts(undefined)}
          />
          <Collapse in={loading}>
            <CircularProgress color="secondary" />
          </Collapse>
          <Collapse in={attempts !== undefined}>
            <Alert
              severity="warning"
              icon={false}
              sx={{ justifyContent: "center" }}
            >
              <Typography>Immatriculation non reconnue.</Typography>
              {attempts === 0 ? (
                <Stack>
                  <Typography>
                    Vous avez atteint le nombre maximum d’essais.
                  </Typography>
                  <Typography>Merci de contacter votre expert</Typography>
                </Stack>
              ) : (
                <Typography>Il vous reste {attempts} essais !</Typography>
              )}
            </Alert>
          </Collapse>
        </Stack>
      </Container>
    </VerticalCentered>
  );
};
