import { FC, useEffect, useState } from "react";

import { SignatureSummary } from "~models/SignatureSummary";
import { StepProps } from "~pages/esign";
import { CoholderFinalization } from "~pages/esign/posthoc/CoholderFinalization";
import { Summary } from "~pages/esign/posthoc/Summary";
import { InstructionCG } from "~pages/esign/posthoc/instructionCG";
import { esignService } from "~services/esignService";
import { showCGInstruction } from "~utils/signatureRequest";

export interface PosthocProps {
  signatureSummary: SignatureSummary;
  back?: () => void;
  next?: () => void;
}

export type PosthocStep = FC<PosthocProps>;
export const Posthoc: FC<StepProps<"Posthoc">> = ({
  signatureSummary: summary,
  step,
  setStep,
}) => {
  const [signatureSummary, setSignatureSummary] = useState(summary);
  const { checker, signatureData } = signatureSummary;

  useEffect(() => {
    esignService
      .getSignatureSummary()
      .then((signature) => setSignatureSummary(signature));
  }, [step]);

  const user = signatureData.currentUser;
  const holder = signatureData.users.find((it) => it.isHolder);
  const holderIsLegalPerson = holder?.isLegalPerson || false;
  const hasMultiSigners = signatureData.users.length > 1;
  const teminated = signatureData.status === "Terminated";

  const restart = () => setStep({ value: "Posthoc", subStep: 0 });

  const steps: PosthocStep[] = [
    ...(!user.isHolder &&
    (!checker || (checker.totalTries < 2 && checker.lastTryContainsErrors))
      ? [CoholderFinalization]
      : []),
    ...(user.isHolder && !teminated && hasMultiSigners ? [Summary] : []),
    ...(teminated && showCGInstruction(signatureSummary)
      ? InstructionCG({
          hasMultiSigners,
          holderIsLegalPerson,
          restart,
          checker,
        })
      : []),
  ];
  const incStep = (inc: number) =>
    steps?.[step.subStep + inc] &&
    (() => setStep({ ...step, subStep: step.subStep + inc }));

  const end = () => setStep({ value: "End" });
  const next = incStep(1) || (teminated ? end : undefined);
  const back = incStep(-1);

  if (steps.length === 0) {
    end();
    return null;
  }
  const CurrentStep = steps.at(
    step.subStep >= steps.length ? -1 : step.subStep
  );

  if (!CurrentStep) {
    end();
    return null;
  }
  return (
    <CurrentStep next={next} back={back} signatureSummary={signatureSummary} />
  );
};
