import React, { FC } from "react";

import { FormControl, MenuItem, TextField, Typography } from "@mui/material";

import { useMuiForm } from "~hooks/useMuiForm";

export const RenteeCGInfo: FC<{ readOnly?: boolean }> = ({ readOnly }) => {
  const { register, watch } = useMuiForm("renterInformation");
  return (
    <FormControl fullWidth>
      <Typography fontWeight="bold" my={2}>
        Où est la carte grise?
      </Typography>
      <TextField
        label="Où est la carte grise"
        select
        defaultValue={
          watch("hasCG") === undefined || watch("hasCG") === null
            ? ""
            : watch("hasCG").toString()
        }
        {...register("hasCG")}
        InputProps={{ readOnly }}
      >
        <MenuItem value="false">Chez le locataire</MenuItem>
        <MenuItem value="true">En votre possession</MenuItem>
      </TextField>
    </FormControl>
  );
};
