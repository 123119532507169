import React, { useState } from "react";

import { useLoaderData, useMatch } from "react-router-dom";

import { UserLoginInfo } from "~models/UserLoginInfo";

import { Cookie } from "./Cookie";
import { CookieRefused } from "./CookieRefused";
import { Login } from "./Login";

export const SignIn = () => {
  const match = useMatch(":loginUrl");
  const userLoginInfo = useLoaderData() as UserLoginInfo;
  const [cookiesAccepted, setCookies] = useState<boolean>();

  if (cookiesAccepted === undefined) {
    return (
      <Cookie
        accept={() => setCookies(true)}
        refuse={() => setCookies(false)}
        info={userLoginInfo}
      />
    );
  } else if (cookiesAccepted) {
    return <Login loginUrl={match?.params.loginUrl!} info={userLoginInfo} />;
  } else {
    return <CookieRefused onModify={() => setCookies(undefined)} />;
  }
};
