import React, { FC, useState } from "react";

import { SignatureLink } from "~models/SignatureLink";
import { SignatureSummary } from "~models/SignatureSummary";
import { UserChoice } from "~models/UserChoice";
import { UserChoiceOptions } from "~models/UserChoiceOptions";
import { End } from "~pages/esign/End";
import { Signature } from "~pages/esign/Signature";
import { Choice } from "~pages/esign/choice/Choice";
import { CoholderChoice } from "~pages/esign/choice/CoholderChoice";
import { Conclusion } from "~pages/esign/conclusion/Conclusion";
import { Posthoc } from "~pages/esign/posthoc";
import { Recap } from "~pages/esign/recap";
import { userSigned } from "~utils/signatureRequest";

import { useSignatureSummary } from "../../layouts/EsignLayout";

export type Step =
  | { value: "Conclusion" }
  | { value: "Choice" }
  | { value: "Recap"; choice: UserChoice }
  | {
      value: "Signature";
      choice: UserChoice;
      options: UserChoiceOptions;
      link: SignatureLink;
    }
  | { value: "Posthoc"; subStep: number }
  | { value: "End" };

export interface StepProps<T extends Step["value"]> {
  step: Extract<Step, Record<"value", T>>;
  setStep: (step: Step) => void;
  signatureSummary: SignatureSummary;
}

const initialStep: (signed: Boolean) => Step = (signed) =>
  signed ? { value: "Posthoc", subStep: 0 } : { value: "Conclusion" };

export const Esign: FC = () => {
  const [signatureSummary] = useSignatureSummary();
  const currentUser = signatureSummary.signatureData.currentUser;
  const [step, setStep] = useState<Step>(
    initialStep(userSigned(signatureSummary))
  );

  const props = { signatureSummary, setStep };
  switch (step.value) {
    case "Conclusion":
      return <Conclusion step={step} {...props} />;
    case "Choice":
      const Step = currentUser.isHolder ? Choice : CoholderChoice;
      return <Step step={step} {...props} />;
    case "Recap":
      return <Recap step={step} {...props} />;
    case "Signature":
      return <Signature step={step} {...props} />;
    case "Posthoc":
      return <Posthoc step={step} {...props} />;
    case "End":
      return <End step={step} {...props} />;
  }
};
