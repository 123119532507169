import React, { FC, useCallback, useState } from "react";

import { Visibility } from "@mui/icons-material";
import {
  Alert,
  Button,
  CardMedia,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";

import { BottomSheet } from "~components/BottomSheet";
import { ButtonGroup } from "~components/ButtonGroup";
import { CircledIcon } from "~components/CircledIcon";
import { IllustratedContent } from "~components/IllustratedContent";
import { NextButton } from "~components/NextButton";
import { Template } from "~components/Template";
import { useMediaDesktop } from "~hooks/useMediaDesktop";
import { useToggle } from "~hooks/useToggle";
import { AddCGPhotoSvg } from "~illustrations";
import { AddCGStep, ModifyCGStep } from "~models/StepStatus";
import { PosthocProps } from "~pages/esign/posthoc";
import { FileWithData, resizeInputImage } from "~utils/files";

const CleanPhoto = () => (
  <Stack direction="row" alignItems="center" width="100%" textAlign="left">
    <CircledIcon>
      <Visibility />
    </CircledIcon>
    <Typography>
      Vérifiez que la photo est nette et bien cadrée avant de valider.
    </Typography>
  </Stack>
);

const AcceptTypes = ["image/jpeg", "image/png", "application/pdf"];
const Accept = AcceptTypes.join(",");

interface AddCGPhotoProps extends PosthocProps {
  onValidate: (file: FileWithData) => void;
  defaultCg?: FileWithData;
}

export const AddCGPhoto: FC<AddCGPhotoProps> = ({
  back,
  next,
  signatureSummary,
  onValidate,
  defaultCg,
}) => {
  const desktop = useMediaDesktop();
  const [cg, setCG] = useState<FileWithData | undefined>(defaultCg);
  const [error, setError] = useState<string>();
  const mandatoryCGCheck = signatureSummary.configData.mandatoryCGCheck;
  const [confirmSkip, toggleConfirmSkip] = useToggle(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length !== 1) {
      setError("Veuillez ajouter une photo.");
    } else if (!AcceptTypes.includes(event.target.files[0].type)) {
      setError("Le format de la photo n'est pas supporté.");
    } else {
      const file = event.target.files[0];
      setError(undefined);
      if (file.type === "application/pdf") {
        if (file.size < 1900000) {
          const src = URL.createObjectURL(file);
          setCG({ file, data: src });
        }
      } else {
        resizeInputImage(file).then(setCG);
      }
    }
  };

  const handleValidate = useCallback(() => {
    if (cg) onValidate(cg);
    else setError("Veullez d'abord séléctionner la photo.");
  }, [cg, onValidate]);

  const errorSnackbar = (
    <Snackbar open={Boolean(error)}>
      <Alert color="error" onClose={() => setError(undefined)}>
        {error}
      </Alert>
    </Snackbar>
  );
  return cg ? (
    <Template
      back={back}
      title="Ajouter une photo"
      verticalCentered={desktop}
      progress={80}
      stepStatus={ModifyCGStep}
    >
      <IllustratedContent
        illustrationHeight="50vh"
        illustration={
          cg.file.type === "application/pdf" ? (
            <embed
              src={`${cg.data}#toolbar=0&navpanes=0&scrollbar=0`}
              width="100%"
              height="100%"
            ></embed>
          ) : (
            <CardMedia
              image={cg.data}
              sx={{ backgroundSize: "contain", height: "100%" }}
            />
          )
        }
      >
        <CleanPhoto />
        <Button variant="outlined" fullWidth component="label">
          Modifier la photo
          <input type="file" accept={Accept} hidden onChange={handleChange} />
        </Button>
        <NextButton onClick={handleValidate}>Valider</NextButton>
      </IllustratedContent>
      {errorSnackbar}
    </Template>
  ) : (
    <Template
      back={back}
      title="Ajouter une photo"
      verticalCentered={desktop}
      progress={70}
      stepStatus={AddCGStep}
    >
      <IllustratedContent
        illustrationHeight={{ xs: "25vh", md: "50vmin" }}
        illustration={
          <AddCGPhotoSvg style={{ height: "100%", width: "100%" }} />
        }
      >
        <Typography>
          Prenez en photo le côté{" "}
          <Typography
            sx={{
              textDecorationLine: "underline",
              fontWeight: "bold",
              display: "block",
            }}
            component="span"
          >
            barré, daté et signé
          </Typography>{" "}
          de votre carte grise.
        </Typography>
        <ButtonGroup>
          <NextButton component="label">
            Ajouter une photo
            <input type="file" accept={Accept} hidden onChange={handleChange} />
          </NextButton>
          {!mandatoryCGCheck && (
            <Link textAlign="center" onClick={toggleConfirmSkip}>
              Passer cette étape
            </Link>
          )}
        </ButtonGroup>
      </IllustratedContent>
      <BottomSheet open={confirmSkip} onClose={toggleConfirmSkip}>
        <DialogTitle>Passer cette étape</DialogTitle>
        <DialogContent>
          Joindre la photo de votre carte grise barrée, datée et signée permet
          d'accélérer le traitement de votre dossier et de contrôler que les
          consignes ont bien été respectées. Confirmez vous votre choix ?
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={toggleConfirmSkip}>
            Annuler
          </Button>
          <Button variant="contained" onClick={next}>
            Confirmer
          </Button>
        </DialogActions>
      </BottomSheet>
      {errorSnackbar}
    </Template>
  );
};
