import React, { FC, PropsWithChildren } from "react";

import { Stack, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material/styles/createTheme";

export const ButtonGroup: FC<PropsWithChildren> = ({ children }) => {
  const mdDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  return (
    <Stack spacing={2} width={mdDown ? "100%" : "initial"}>
      {children}
    </Stack>
  );
};
