import { SinisterType } from "~models/SinisterType";

export const RecommendationTexts: (insurance: string) => {
  [key in SinisterType]: string;
} = (insurance: string) => ({
  VEI_SANS_VGE: `Votre véhicule est techniquement réparable, mais économiquement irréparable : le montant estimé des réparations est supérieur à la valeur de remplacement à dire d'expert (VRADE).\nVotre assurance ${insurance} vous propose de lui céder le véhicule.`,
  VEI_AVEC_VGE: `Votre véhicule est techniquement réparable, mais classé "gravement endommagé (VGE)" et économiquement irréparable : le montant estimé des réparations est supérieur à la valeur de remplacement à dire d'expert (VRADE).\nVotre assurance ${insurance} vous propose de lui céder le véhicule.`,
  RIV_SANS_VGE: `Votre véhicule est techniquement réparable, mais le montant estimé des réparations avoisine la valeur de remplacement à dire d'expert (VRADE).\nEn raison des dommages complémentaires qui pourraient apparaître lors du démontage, votre assurance ${insurance} vous propose de lui céder le véhicule.`,
  RIV_AVEC_VGE: `Votre véhicule est techniquement réparable, mais classé "gravement endommagé (VGE)" et le montant estimé des réparations avoisine la valeur de remplacement à dire d'expert (VRADE).\nEn raison des dommages complémentaires qui pourraient apparaître lors du démontage, votre assurance ${insurance} vous propose de lui céder le véhicule.`,
  TNR: `Compte tenu de la nature des dommages subis par votre véhicule, celui-ci a été classé techniquement non réparable. En conséquence, aucune réparation et/ou remise en circulation ne peut être envisagée. Votre assurance ${insurance} vous propose de lui céder le véhicule. Conformémemnt à la législation, l'assurance se chargera alors de le faire déconstruire par un recycleur agréé.`,
  VNR: `Suite au vol de votre véhicule, votre assureur vous propose une indemnisation basée sur la valeur ci-dessus. Pour être indemnisé(e), vous devez lui céder votre véhicule. Important : en fonction des garanties que vous avez souscrites, il pourra y avoir des écarts entre cette valeur et l'indemnisation finale.`,
  CONSERVATOIRE_AXA_AVEC_VGE: `Votre véhicule a été identifié comme "Véhicule Gravement Endommagé (VGE)" et se voit appliquer plusieurs restrictions administratives. De plus, en l'état actuel de votre dossier, votre sinistre n'est pas pris en charge. Cependant votre assureur AXA vous propose de lui céder le véhicule afin de vous permettre de vous en séparer en toute conformité.`,
  CONSERVATOIRE_AXA_SANS_VGE: `En l'état actuel de votre dossier votre sinistre n'est pas pris en charge. Cependant votre assureur AXA vous propose de lui céder le véhicule afin de vous permettre de vous en séparer en toute conformité.`,
});

export const ResidualValueInfo: string = `La valeur après sinistre, également appelée valeur de sauvetage ou valeur résiduelle, correspond au montant du véhicule accidenté, endommagé lors du sinistre, définit par convention entre l'assureur et ses partenaires (professionnels de la récupération automobile).`;
export const EstimateRepairInfo: string = `Evaluation des réparations réalisée sur la base des dommages apparents, avant démontage et selon les tarifs horaires appliqués par le professionnel chez qui votre véhicule a été déposé. Ce montant est susceptible d'évoluer en cours de travaux, notamment si des dommages complémentaires sont découverts.`;
export const EstimateValueInfo = (sinisterType: SinisterType): string => {
  switch (sinisterType) {
    case "VNR":
      return `Il s'agit d'une valeur de marché établie en fonction de l'état déclaré de votre véhicule et des documents transmis. Cette valeur concerne uniquement votre véhicule et ne peut servir de référence pour le modèle examiné. La valeur établie est technique et ne tient pas compte des conditions particulières de votre contrat.`;
    default:
      return `Il s'agit de la valeur à dire d'expert. Cette valeur est établie par le cabinet d'expertise, en fonction de l'état constaté de votre véhicule et des documents transmis. Cette valeur concerne uniquement votre véhicule et ne peut servir de référence pour le modèle examiné. La valeur établie est technique et ne tient pas compte des conditions particulières de votre contrat.`;
  }
};
