import React, { FC } from "react";

import { SvgIcon } from "@mui/material";

import { ColorIconProps, Path } from "~icons/index";

export const CarCrash: FC<ColorIconProps> = ({
  primaryColor,
  secondaryColor = "primary.dark",
  size,
}) => (
  <SvgIcon inheritViewBox fontSize={size}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
      <Path
        color={primaryColor}
        d="m638 353-13 47a63 63 0 0 1-26 36l-14 52a32 32 0 0 1-39 23l-31-8c-17-5-27-22-23-40l9-30-248-67-8 31c-4 17-22 27-39 23l-31-8a32 32 0 0 1-23-40l14-52-4-10c-3-11-3-23 0-35l12-46a64 64 0 0 1 56-47l18-24 32-43a112 112 0 0 1 118-41l123 33a113 113 0 0 1 82 95l7 53 3 30a64 64 0 0 1 25 68zM256 234c-19-5-35 4-40 23-5 18 5 34 23 39 19 5 46 15 51-3 5-19-16-54-34-59zm290 29-6-54a48 48 0 0 0-35-40l-123-33c-19-5-39 2-51 17l-32 43 172 46zm19 54c-19-5-54 15-59 34-5 18 24 23 42 28 19 5 34-4 39-23 5-18-4-34-22-39z"
      />
      <Path
        color={secondaryColor}
        d="m143 221-12 46c-3 11-4 23-3 35l-35 29c-6 5-16-1-14-9l15-77c1-5-3-10-8-11l-78-7a9 9 0 0 1-4-17l65-43c5-3 6-9 2-13L21 93c-5-6 1-16 9-14l77 15c5 1 10-3 11-8l7-78c1-8 12-11 17-4l43 65c3 5 9 6 13 2l61-50c6-5 16 1 14 9l-11 56-8 10-32 43-11 14c-32 9-59 35-68 68z"
      />
    </svg>
  </SvgIcon>
);
