import { FC, useState } from "react";

import { StepProps } from "~pages/esign";

import { OtherChoice } from "./OtherChoice";
import { SameChoice } from "./SameChoice";

export const CoholderChoice: FC<StepProps<"Choice">> = ({
  signatureSummary,
  setStep,
}) => {
  const [sameChoice, setSameChoice] = useState(true);
  const holder = signatureSummary.signatureData.users.find(
    (it) => it.isHolder
  )!;
  const holderChoice = holder.signature!.choice;
  if (sameChoice) {
    return (
      <SameChoice
        holder={holder}
        onBack={() => setStep({ value: "Conclusion" })}
        onNext={() => setStep({ value: "Recap", choice: holderChoice })}
        onOtherChoice={() => setSameChoice(false)}
      />
    );
  } else {
    return (
      <OtherChoice
        holder={holder}
        choices={signatureSummary.choices}
        onBack={() => setSameChoice(true)}
        onNext={(choice) => setStep({ value: "Recap", choice })}
      />
    );
  }
};
