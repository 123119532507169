import { FC, PropsWithChildren } from "react";

import { Box } from "@mui/material";
import styled from "@mui/material/styles/styled";

import { fullHeight } from "~utils/fullHeight";

export const VerticalCenteredWrapper = styled(Box)({
  ...fullHeight("minHeight"),
  display: "grid",
  gridTemplateRows: "1fr auto 3fr",
});

export const VerticalCentered: FC<PropsWithChildren> = ({ children }) => (
  <VerticalCenteredWrapper>
    <div />
    <Box>{children}</Box>
    <div />
  </VerticalCenteredWrapper>
);
