import React, { useRef } from "react";

export const useDynamicRefs = (
  numberOfRefs: number
): Array<React.MutableRefObject<HTMLElement>> => {
  const refs = useRef([]);
  if (refs.current.length !== numberOfRefs) {
    refs.current = Array.from(
      { length: numberOfRefs },
      (_, i) => refs.current[i] || React.createRef()
    );
  }

  return refs.current;
};
