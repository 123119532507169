import { ColorSystemOptions, SupportedColorScheme } from "@mui/material";

import { ThemeColors } from "~models/ThemeColors";

const palette: (
  colors: ThemeColors
) => Partial<Record<SupportedColorScheme, ColorSystemOptions>> = ({
  main,
  dark,
  light,
}) => ({
  light: {
    palette: {
      background: {
        default: "#FAFAFA",
        paper: "#FFFFFF",
      },
      primary: {
        main,
        dark,
        light,
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#FFFFFF",
        light: "#FFFFFF",
        dark: "#E8EEFB",
        contrastText: main,
      },
      success: {
        main: "#0CBC8B",
      },
    },
  },
  dark: {
    palette: {
      background: {
        default: main,
        paper: main,
      },
      primary: {
        main: "#FFFFFF",
        light: "#FFFFFF",
        dark: "#E8EEFB",
        contrastText: main,
      },
      secondary: {
        main,
        dark,
        light,
        contrastText: "#ffffff",
      },
      error: {
        main: "#e57373",
      },
    },
  },
});

export default palette;
