import React, { FC } from "react";

import { useAuth } from "~hooks/useAuth";
import { Fallback } from "~pages/Fallback";

interface Props {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
export const AuthGuard: FC<Props> = ({ children }) => {
  const { authenticated } = useAuth();

  return authenticated ? <>{children}</> : <Fallback />;
};
