import { Card, CardContent, Stack, Typography } from "@mui/material";

import { IllustratedContent } from "~components/IllustratedContent";
import { Template } from "~components/Template";
import { VerifyCGSVG } from "~components/illustrations";
import { useMediaDesktop } from "~hooks/useMediaDesktop";
import { ModifyCGStep } from "~models/StepStatus";

export const InvalidCG = () => {
  const desktop = useMediaDesktop();

  return (
    <Template
      title="Analyse en cours"
      verticalCentered={desktop}
      progress={100}
      stepStatus={ModifyCGStep}
    >
      <IllustratedContent
        illustrationHeight={{ xs: "25vh", md: "50vmin" }}
        illustration={<VerifyCGSVG style={{ height: "100%", width: "100%" }} />}
      >
        <Typography
          sx={{
            fontWeight: "bold",
          }}
        >
          Votre dossier est en cours d'analyse
        </Typography>
        <Card variant="soft">
          <CardContent>
            <Stack direction="row">
              <Stack spacing={2}>
                <Typography>
                  Notre équipe reviendra vers vous sous 24 heures (hors week end
                  et jours fériés).
                </Typography>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </IllustratedContent>
    </Template>
  );
};
