import React, { FC } from "react";

import { RenteeInfoSchema } from "~models/Schemas";
import { RenteeCGInfo } from "~pages/esign/recap/RenteeCGInfo";
import { RenterSelector } from "~pages/esign/recap/RenterSelector";

import { LegalPersonInfo } from "./LegalPersonInfo";
import { RenteeAddress } from "./RenteeAddress";
import { RecapStep, RecapStepProps } from "./index";

const RenteeInfoFC: FC<RecapStepProps> = (props) =>
  !props.signatureSummary.renterSummary ? (
    <>
      <LegalPersonInfo {...props} />
      {props.step.choice === "sell" && <RenteeCGInfo />}
      <RenteeAddress signatureSummary={props.signatureSummary} />
    </>
  ) : (
    <>
      <RenterSelector {...props} />
      {props.step.choice === "sell" && <RenteeCGInfo readOnly />}
    </>
  );

export const RenteeInfo: RecapStep = Object.assign(RenteeInfoFC, {
  title: "Informations complémentaires",
  schema: RenteeInfoSchema,
});
