import React, { FC } from "react";

import { SvgIcon } from "@mui/material";

import { ColorIconProps, Path } from "~icons/index";

export const CarValue: FC<ColorIconProps> = ({
  primaryColor,
  secondaryColor = "primary.dark",
  size,
}) => (
  <SvgIcon inheritViewBox fontSize={size}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 409.18">
      <svg viewBox="0 0 510 395">
        <Path
          d="M431 0a148 148 0 0 0-13 1 120 120 0 0 0-17 2 120 120 0 0 0-4 1 120 120 0 0 0-13 4 120 120 0 0 0-4 2 120 120 0 0 0-15 8 114 114 0 0 0-24 20 132 132 0 0 0-22 32 150 150 0 0 0-12 35h-29l-12 32h37v14a270 270 0 0 0 0 6v8h-25l-12 33h42a155 155 0 0 0 11 35 146 146 0 0 0 6 10 118 118 0 0 0 40 41 116 116 0 0 0 28 13 135 135 0 0 0 38 5 150 150 0 0 0 4 0 132 132 0 0 0 38-6 109 109 0 0 0 36-20l-24-49-7 5a215 215 0 0 1-6 4 79 79 0 0 1-8 4 103 103 0 0 1-10 3 67 67 0 0 1-10 3 84 84 0 0 1-13 1 75 75 0 0 1-8-1 59 59 0 0 1-27-9c-6-4-11-10-15-17a81 81 0 0 1-8-14 100 100 0 0 1-2-8h58l16-33h-79a242 242 0 0 1-1-24l1-4h92l14-32H371a109 109 0 0 1 2-8 98 98 0 0 1 1-3l8-14a55 55 0 0 1 14-15 56 56 0 0 1 16-7 67 67 0 0 1 19-3 86 86 0 0 1 9 1 69 69 0 0 1 14 3 105 105 0 0 1 8 3 84 84 0 0 1 11 5 307 307 0 0 1 3 2l11 7 22-49c-10-9-22-16-35-20a117 117 0 0 0-14-4 136 136 0 0 0-29-3Z"
          color={secondaryColor}
        />
        <Path
          color={primaryColor}
          d="M304 11H177a113 113 0 0 0-97 55c-3 4-5 10-7 15l-20 50-12 28a64 64 0 0 0-41 60v48a63 63 0 0 0 16 41v55a32 32 0 0 0 32 32h32c18 0 32-15 32-32v-32h256v32c0 17 14 32 32 32h32a32 32 0 0 0 32-32v-49a148 148 0 0 1-76-2 133 133 0 0 1-83-72v-1l-10-26h-52l22-58H112l20-50c7-18 25-30 44-30h124a144 144 0 0 1 29-48l11-10-3-1c-11-4-22-5-33-5ZM80 203c19 0 48 28 48 47 0 20-29 16-48 16s-32-12-32-32c0-19 13-31 32-31z"
        />
      </svg>
    </svg>
  </SvgIcon>
);
