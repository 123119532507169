import React, { FC, useEffect } from "react";

import { FormProvider } from "react-hook-form";

import { LoadingButton } from "@mui/lab";
import {
  Box,
  Container,
  FormControl,
  FormHelperText,
  Rating,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

import { yupResolver } from "@hookform/resolvers/yup";
import { ButtonGroup } from "~components/ButtonGroup";
import { useMuiRootForm } from "~hooks/useMuiForm";
import { EndSVG, RatingSVG } from "~illustrations";
import { EsignRating } from "~models/EsignRating";
import { RatingSchema } from "~models/Schemas";
import { DoneStep } from "~models/StepStatus";
import { StepProps } from "~pages/esign/index";
import { esignService } from "~services/esignService";

const RatingComponent = () => {
  const methods = useMuiRootForm({ resolver: yupResolver(RatingSchema) });
  const {
    muiRegister: register,
    watch,
    handleSubmit,
    setValue,
    setError,
    formState: { isSubmitting, isSubmitSuccessful, errors },
  } = methods;
  const submitRation = handleSubmit((values) =>
    esignService.submitRating(values as EsignRating).catch((res) => {
      switch (res.response?.status) {
        case 409:
          return;
        case 500:
          return setError("root.serverError", {
            type: res.response.status,
            message: "Une erreur est survenue, veuillez réessayer.",
          });
        default:
          return setError("root.serverError", {
            type: res.response?.status,
            message: "Une erreur est survenue, veuillez réessayer plus tard.",
          });
      }
    })
  );
  const rating = register("rating");
  return (
    <FormProvider {...methods}>
      <FormControl error={rating.error} sx={{ alignItems: "center" }}>
        <Rating
          size="large"
          value={watch("rating") || 0}
          onChange={(_, value) => setValue("rating", value)}
          disabled={isSubmitSuccessful}
        />
        {register("rating").helperText && (
          <FormHelperText>{register("rating").helperText}</FormHelperText>
        )}
      </FormControl>
      <TextField
        {...register("comment")}
        placeholder="Votre commentaire"
        multiline
        fullWidth
        disabled={isSubmitSuccessful}
      />
      {isSubmitSuccessful ? (
        <Typography>Merci pour votre évaluation.</Typography>
      ) : (
        <ButtonGroup>
          <LoadingButton
            variant="outlined"
            fullWidth
            onClick={submitRation}
            loading={isSubmitting}
          >
            Evaluer l’experience
          </LoadingButton>
        </ButtonGroup>
      )}
      {errors.root?.serverError && (
        <Typography>{errors.root.serverError.message}</Typography>
      )}
    </FormProvider>
  );
};
export const End: FC<StepProps<"End">> = ({ signatureSummary }) => {
  const email = signatureSummary.signatureData.currentUser.email;
  useEffect(() => {
    esignService.notifyStepStatus(DoneStep);
  }, []);
  return (
    <>
      <Toolbar />
      <Container sx={{ textAlign: "center" }}>
        <Stack alignItems="center">
          <EndSVG />
          <Typography fontWeight="bold">
            Merci, votre dossier sera finalisé à réception de votre courrier.
          </Typography>
          <Typography>
            Votre signature a bien été prise en compte. Un email récapitulatif a
            été envoyé à {email}
          </Typography>
        </Stack>
      </Container>
      <Box sx={{ backgroundColor: "primary.light" }} mt={8} py={8}>
        <Container>
          <Stack
            direction="row"
            mb={4}
            justifyContent="center"
            alignItems="center"
          >
            <RatingSVG height="4em" />
            <Typography>Impatient de connaitre votre avis !</Typography>
          </Stack>
          <Stack spacing={4} alignItems="center">
            <RatingComponent />
          </Stack>
        </Container>
      </Box>
    </>
  );
};
