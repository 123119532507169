import { FC, useCallback, useEffect, useMemo, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CircularProgress, Grid, Typography } from "@mui/material";

import { IllustratedContent } from "~components/IllustratedContent";
import { Template } from "~components/Template";
import { useMediaDesktop } from "~hooks/useMediaDesktop";
import { VerifyCGSVG } from "~illustrations";
import {
  CheckerError,
  CheckerErrorType,
  CheckerResponse,
} from "~models/CheckerResponse";
import { ModifyCGStep } from "~models/StepStatus";
import { esignService } from "~services/esignService";
import { FileWithData } from "~utils/files";

import { PosthocProps } from "..";
import { ErrorCG } from "./ErrorCG";

interface VerifyCGComponentProps {
  onDocumentSubmited: (response: CheckerResponse) => void;
  onSubmitError: (err?: any) => void;
  cg: FileWithData;
}

const VerifyCGComponent: FC<VerifyCGComponentProps> = ({
  onDocumentSubmited,
  onSubmitError,
  cg,
}) => {
  const [loader, setLoader] = useState(0);
  const [isSubmitError, setIsSubmitError] = useState(false);

  useEffect(() => {
    if (!isSubmitError) {
      const loaderInterval = setInterval(() => {
        setLoader((prev) => prev + 1);
      }, 1000);
      return () => clearInterval(loaderInterval);
    }
  }, [loader, isSubmitError]);

  useEffect(() => {
    esignService
      .submitDocument(cg)
      .then(onDocumentSubmited)
      .catch((error) => {
        setIsSubmitError(true);
        onSubmitError(error);
      });
  }, [cg, onDocumentSubmited, onSubmitError]);

  return (
    <IllustratedContent
      illustrationHeight={{ xs: "25vh", md: "50vmin" }}
      illustration={<VerifyCGSVG style={{ height: "100%", width: "100%" }} />}
    >
      <Typography
        sx={{
          fontWeight: "bold",
        }}
      >
        Analyse en cours
      </Typography>
      <Typography> Veuillez patienter ! </Typography>
      <InformationWithIcon
        description="Format de votre document"
        state={isSubmitError ? "error" : loader < 1 ? "loading" : "done"}
      />
      <InformationWithIcon
        description="Contrôle des données"
        state={isSubmitError ? "error" : loader < 3 ? "loading" : "done"}
      />
      <InformationWithIcon
        description="Contrôle des consignes"
        state={isSubmitError ? "error" : "loading"}
      />
      {isSubmitError && (
        <Typography color={"red"}>
          Une erreur s'est produite lors de la validation. Veuillez réessayer.
        </Typography>
      )}
    </IllustratedContent>
  );
};

interface InformationIconProps {
  state: "loading" | "done" | "error";
}

const InformationIcon = ({ state }: InformationIconProps) => {
  if (state === "loading")
    return <CircularProgress size="2.18em" color="primary" />;
  if (state === "done")
    return <CheckCircleIcon fontSize="large" color="primary" />;
  return <CancelIcon color="error" fontSize="large" />;
};

type InformationProps = {
  description: string;
  state: "loading" | "done" | "error";
};

const InformationWithIcon = ({ description, state }: InformationProps) => {
  return (
    <Grid container display="flex" alignItems="center">
      <Grid item xs={2.5} display="flex" textAlign="left" alignItems="center">
        <InformationIcon state={state} />
      </Grid>
      <Grid item xs={9} textAlign="left">
        <Typography flex="1" fontWeight="bold" fontSize="14px">
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

interface VerifyCGProps extends PosthocProps {
  onValid: () => void;
  onInvalid: () => void;
  onErrorRetry: (hasConsigneError: boolean) => void;
  cg: FileWithData;
}

export const VerifyCG: FC<VerifyCGProps> = ({
  onValid,
  onInvalid,
  cg,
  onErrorRetry,
  back,
  signatureSummary: {
    checker,
    configData: { mandatoryCGCheck },
  },
}) => {
  const desktop = useMediaDesktop();
  const [tryErrors, setTryErrors] = useState<Array<CheckerError>>([]);
  const [isErrorRetry, setErrorRetry] = useState(false);
  const [canBack, setCanBack] = useState(false);

  const hasConsigneError = useMemo(() => {
    if (tryErrors.length === 0) return false;
    return tryErrors.reduce((prev, { errorType }) => {
      if (prev) return true;
      if (errorType === CheckerErrorType.CONSIGNE) return true;
      return false;
    }, false);
  }, [tryErrors]);

  const handleErrorRetry = useCallback(
    () => onErrorRetry(hasConsigneError),
    [hasConsigneError, onErrorRetry]
  );

  const handleDocumentSubmited = useCallback(
    ({ currentTryErrors }: CheckerResponse) => {
      setTryErrors(currentTryErrors);
      if (!mandatoryCGCheck) onValid();
      else {
        if (currentTryErrors.length > 0) {
          if (!checker) setErrorRetry(true);
          else onInvalid();
        } else onValid();
      }
    },
    [checker, mandatoryCGCheck, onInvalid, onValid]
  );

  const handleSubmitError = useCallback(
    (err?: any) => {
      if (err?.response?.status === 403) {
        if (!mandatoryCGCheck) setCanBack(true);
        else onInvalid();
      } else setCanBack(true);
    },
    [mandatoryCGCheck, onInvalid]
  );

  return (
    <Template
      title="Analyse document"
      verticalCentered={desktop}
      progress={80}
      stepStatus={ModifyCGStep}
      back={canBack ? back : undefined}
    >
      {isErrorRetry ? (
        <ErrorCG
          errors={tryErrors}
          cg={cg}
          hasConsigneError={hasConsigneError}
          onRetry={handleErrorRetry}
        />
      ) : (
        <VerifyCGComponent
          cg={cg}
          onDocumentSubmited={handleDocumentSubmited}
          onSubmitError={handleSubmitError}
        />
      )}
    </Template>
  );
};
