import React, { FC } from "react";

import { SvgIcon } from "@mui/material";

import { ColorIconProps, Path } from "~icons/index";

export const CarRepair: FC<ColorIconProps> = ({
  primaryColor,
  secondaryColor = "primary.dark",
  size,
}) => (
  <SvgIcon inheritViewBox fontSize={size}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 409.18">
      <Path
        color={primaryColor}
        d="M176.5 12.6c-11.5 0-22.7 1.72-33.27 4.98a111.02 111.02 0 0 0-63.21 50.1 112.35 112.35 0 0 0-7.5 15.3L52.6 132.83l-11.24 28.1a64.32 64.32 0 0 0-16.64 9.34A63.53 63.53 0 0 0 0 220.58v48c0 12.1 3.48 23.3 9.36 32.9a66.61 66.61 0 0 0 6.65 9.04l.01 54.07a32 32 0 0 0 32 32h32a32 32 0 0 0 32-32v-32h246.52V176.71a113.91 113.91 0 0 1-24.08-20.14H111.99l19.94-49.83a48 48 0 0 1 44.57-30.17h127c.8 0 1.57.1 2.36.13a110.78 110.78 0 0 1 22.57-61.34 113.22 113.22 0 0 0-24.92-2.77zM80 204.58c19.2 0 48 28.71 48 47.85 0 19.15-28.8 15.95-48 15.95s-32-12.75-32-31.9c0-19.14 12.8-31.9 32-31.9z"
      />
      <Path
        color={secondaryColor}
        d="M465.82 1.14a7.92 7.92 0 0 0-11.98 6.82v69.5l-37 26.44-37.01-26.43V7.97a7.94 7.94 0 0 0-12.07-6.78 95.12 95.12 0 0 0-46.1 81.55c0 37.05 21.75 69 52.88 84.7V366.9a42.3 42.3 0 0 0 84.58 0V167.6C490.35 152 512 120.04 512 82.75a95.13 95.13 0 0 0-46.18-81.61ZM428.05 378.1a15.87 15.87 0 1 1-22.44-22.45 15.87 15.87 0 0 1 22.44 22.45z"
      />
    </svg>
  </SvgIcon>
);
