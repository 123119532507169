import { UserChoice } from "~models/UserChoice";

export type StepStatus =
  | { type: "Conclusion" }
  | { type: "Choice" }
  | { type: "CoholderChoice" }
  | { type: "OtherChoice" }
  | { type: "Recap"; choice: UserChoice; step: number }
  | { type: "Signature"; choice: UserChoice }
  | { type: "Disagreed"; choice: UserChoice }
  | { type: "SignedStep" }
  | { type: "CoholderFinalization" }
  | { type: "SignLater" }
  | { type: "Summary" }
  | { type: "CGInstruction"; step: number }
  | { type: "AddCG" }
  | { type: "ModifyCG" }
  | { type: "SendInstruction" }
  | { type: "Done" };

export const ConclusionStep: StepStatus = { type: "Conclusion" };
export const ChoiceStep: StepStatus = { type: "Choice" };
export const CoholderChoiceStep: StepStatus = { type: "CoholderChoice" };
export const OtherChoiceStep: StepStatus = { type: "OtherChoice" };
export const SignedStepStep: StepStatus = { type: "SignedStep" };
export const CoholderFinalizationStep: StepStatus = {
  type: "CoholderFinalization",
};
export const SignLaterStep: StepStatus = { type: "SignLater" };
export const SummaryStep: StepStatus = { type: "Summary" };
export const AddCGStep: StepStatus = { type: "AddCG" };
export const ModifyCGStep: StepStatus = { type: "ModifyCG" };

export const SendInstructionStep: StepStatus = { type: "SendInstruction" };
export const DoneStep: StepStatus = { type: "Done" };
