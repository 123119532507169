import React, { FC } from "react";

import { Button, Typography } from "@mui/material";

import { ButtonGroup } from "~components/ButtonGroup";
import { CircledIcon } from "~components/CircledIcon";
import { NextButton } from "~components/NextButton";
import { Template } from "~components/Template";
import { useMediaDesktop } from "~hooks/useMediaDesktop";
import { ConclusionStep } from "~models/StepStatus";
import { User } from "~models/User";
import { ChoiceIcon, ChoiceLabelFor } from "~utils/wording";

interface Props {
  holder: User;
  onBack: () => void;
  onNext: () => void;
  onOtherChoice: () => void;
}

export const SameChoice: FC<Props> = ({
  holder,
  onBack,
  onNext,
  onOtherChoice,
}) => {
  const desktop = useMediaDesktop();
  const HolderChoiceIcon = ChoiceIcon[holder.signature!.choice];
  return (
    <Template
      title="Votre décision"
      back={onBack}
      progress={20}
      maxWidth="xs"
      verticalCentered={desktop}
      stepStatus={ConclusionStep}
    >
      <CircledIcon fontSize="large">
        <HolderChoiceIcon size="large" />
      </CircledIcon>
      <Typography>
        Le titulaire {holder.lastName} {holder.firstName} a choisi de{" "}
        {ChoiceLabelFor(holder)} le véhicule à l’assureur.
      </Typography>

      <ButtonGroup>
        <Button onClick={onOtherChoice} variant="outlined" fullWidth>
          Faire un autre choix
        </Button>
        <NextButton onClick={onNext}>Valider le choix</NextButton>
      </ButtonGroup>
    </Template>
  );
};
