import React, { useEffect, useState } from "react";

import { Box, TextField } from "@mui/material";

import { useDynamicRefs } from "~hooks/useDynamicRef";
import { useScreenWidth } from "~hooks/useScreenWidth";
import {
  getMatriculationMask,
  processMatriculation,
  removeSeparator,
} from "~utils/matriculation";

interface Props {
  matriculation: string;
  disabled: boolean;
  hasExeption: boolean;
  onAllFieldsFilled: (value: string) => void;
  rejectExeption: () => void;
}

const L_INPUTS_STYLE = {
  BOX: { "& > :not(style)": { m: 0.55, width: "7ch" } },
  SPAN: {
    display: "inline-block",
    width: "1.4ch",
    fontSize: "36px",
    fontWeight: 600,
  },
  TEXTFIELD_INPUT_PROPS: { height: "60px", width: "40px" },
  INPUT_PROPS: { fontSize: "36px" },
};

const M_INPUTS_STYLE = {
  BOX: { "& > :not(style)": { m: 0.25, width: "auto" } },
  SPAN: {
    display: "inline-block",
    width: "1.4ch",
    fontSize: "20px",
    fontWeight: 600,
  },
  TEXTFIELD_INPUT_PROPS: { height: "48px", width: "30px" },
  INPUT_PROPS: { fontSize: "20px" },
};

const S_INPUTS_STYLE = {
  BOX: { "& > :not(style)": { m: 0.19, width: "21px" } },
  SPAN: {
    display: "inline-block",
    width: "1.4ch",
    fontSize: "16px",
    fontWeight: 600,
  },
  TEXTFIELD_INPUT_PROPS: { height: "40px", width: "21px" },
  INPUT_PROPS: { fontSize: "16px" },
};

// Handle to return style for the inputs according into the screen
const generateDynamicInputStyles = (screenWidth: number) => {
  if (screenWidth <= 500 && screenWidth > 350) {
    return M_INPUTS_STYLE;
  }
  if (screenWidth <= 350) {
    return S_INPUTS_STYLE;
  }
  return L_INPUTS_STYLE;
};

const MaskedLoginField = (props: Props) => {
  const {
    matriculation,
    disabled,
    hasExeption,
    onAllFieldsFilled,
    rejectExeption,
  } = props;

  const screenWidth = useScreenWidth(); // Check screenWidth
  const MY_STYLE = generateDynamicInputStyles(screenWidth); // get style according into screenWidth

  const matriculationWithoutSeparator: string = removeSeparator(matriculation);
  const inputFieldLength: number = matriculationWithoutSeparator.length; // Check number of input by exclude separators
  const refs = useDynamicRefs(inputFieldLength); // Create the dynamicRef according off all input

  const emptyInputValue: Array<string> = Array(inputFieldLength).fill("");
  const [values, setValues] = useState<Array<string>>(emptyInputValue);

  const mask = getMatriculationMask(matriculation);
  const isDashSeparator: boolean = matriculation.includes("-");

  const lastIndex: number = inputFieldLength - 1;
  const nextToLastIndex: number = inputFieldLength - 2;

  // check the comportement (type and mask) of fist and second part of input
  const { firstSection, secondSection } = processMatriculation(
    matriculation,
    isDashSeparator
  );

  const handleFocusNext = (index: number) => {
    if (index < refs.length - 1) refs[index + 1].current.focus();
  };

  const handleFocusPrevious = (index: number) => {
    if (index > 0) refs[index - 1].current.focus();
  };

  const handleChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      let isValidInput = false;
      const inputChar = event.target.value.toUpperCase().slice(0, 1);

      // Verify mask validation
      const isMiddleSectionImmatriculationIndex: boolean =
        index >= firstSection.count &&
        index < firstSection.count + secondSection.count;

      if (mask === "aa-000-aa" || mask === "a[a] 0[00] a") {
        // Mask controle for "aa-000-aa" and "a[a] 0[00] a"
        if (isMiddleSectionImmatriculationIndex && /[0-9]/.test(inputChar)) {
          isValidInput = true;
        } else if (
          !isMiddleSectionImmatriculationIndex &&
          /[A-Z]/.test(inputChar)
        ) {
          isValidInput = true;
        }
      } else {
        // Mask for "0[000] a[aa] 00"
        if (isMiddleSectionImmatriculationIndex && /[A-Z]/.test(inputChar)) {
          isValidInput = true;
        } else if (
          !isMiddleSectionImmatriculationIndex &&
          /[0-9]/.test(inputChar)
        ) {
          isValidInput = true;
        }
      }

      if (isValidInput || event.target.value === "") {
        const newValues: Array<string> = [...values];
        newValues[index] = inputChar;

        // Auto fill lastInput and nextToLastIndex if it's empty
        if (!values[lastIndex] && !values[lastIndex]) {
          newValues[lastIndex] = matriculationWithoutSeparator[lastIndex];
          newValues[nextToLastIndex] =
            matriculationWithoutSeparator[nextToLastIndex];
        }

        setValues(newValues);

        if (inputChar) {
          handleFocusNext(index);
        }

        // Reject hasExeption
        if (hasExeption) {
          rejectExeption();
        }
      }
    };

  const handleKeyDown =
    (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Backspace" && !values[index]) {
        handleFocusPrevious(index);
      }
    };

  const insertSeparator = (val: string, isDash: boolean): string => {
    if (val.length < refs.length - 1) {
      return val;
    }
    const sep: string = isDash ? "-" : " ";
    return `${val.slice(0, firstSection.count)}${sep}${val.slice(
      firstSection.count,
      firstSection.count + secondSection.count
    )}${sep}${val.slice(firstSection.count + secondSection.count)}`;
  };

  // Check the index is the 2 last matriculation
  const isHiddenInput = (index: number): boolean =>
    index >= inputFieldLength - 2;

  useEffect(() => {
    if (hasExeption) {
      setValues(emptyInputValue);
    }
    // eslint-disable-next-line
  }, [hasExeption]);

  useEffect(() => {
    const concatenatedValues = values.join("");
    if (concatenatedValues.length === refs.length && !hasExeption) {
      onAllFieldsFilled(insertSeparator(concatenatedValues, isDashSeparator));
    }
    // eslint-disable-next-line
  }, [values]);

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "nowrap",
        ...MY_STYLE.BOX,
      }}
      noValidate
      autoComplete="off"
    >
      {values.map((value, index) => (
        <React.Fragment key={`outlined-basic${index + 1}`}>
          {index === firstSection.count ||
          index === firstSection.count + secondSection.count ? (
            <span style={MY_STYLE.SPAN}>{isDashSeparator ? "-" : " "}</span>
          ) : null}
          <TextField
            sx={{
              display: `${isHiddenInput(index) ? "none" : ""}`,
            }}
            variant="outlined"
            size="small"
            inputRef={refs[index]}
            value={value}
            onChange={handleChange(index)}
            onKeyDown={handleKeyDown(index)}
            InputProps={{
              style: MY_STYLE.TEXTFIELD_INPUT_PROPS,
            }}
            inputProps={{
              maxLength: 1,
              style: {
                textAlign: "center",
                fontWeight: 600,
                marginLeft: "-13px",
                marginRight: "-13px",
                ...MY_STYLE.INPUT_PROPS,
              },
            }}
            disabled={disabled}
          />
          {isHiddenInput(index) && (
            <span style={MY_STYLE.SPAN}>
              {matriculationWithoutSeparator[index]}
            </span>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default MaskedLoginField;
