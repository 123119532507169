import { Paper, Typography } from "@mui/material";

export const Footer = () => (
  <Paper
    sx={{
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      padding: (theme) => theme.spacing(1, 0, 2, 0),
      borderRadius: 0,
    }}
  >
    <Typography fontSize="10px">
      conçu par{" "}
      <img
        src="/static/img/brands/logo_evalucar.svg"
        alt="Evalucar logo"
        height="24px"
        style={{ marginLeft: "4px", marginBottom: "-6px" }}
      />
    </Typography>
  </Paper>
);
