import { FC, PropsWithChildren, createContext } from "react";

import { useLocalStorage } from "~hooks/useLocalStorage";
import { LoginResponse } from "~models/LoginResponse";
import { LogIn, authService } from "~services/authService";

interface AuthContextType {
  bearer?: string;
  authenticated: boolean;
  login: (args: LogIn) => Promise<LoginResponse>;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextType>({
  authenticated: false,
  login: () => Promise.reject(),
  logout: () => {},
});

export const AuthProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [bearer, setBearer, removeBearer] =
    useLocalStorage<string>("esignToken");

  const login = async (args: LogIn) => {
    const res = await authService.logIn(args);
    setBearer(res.authorization);
    return res;
  };
  const logout = removeBearer;
  const authenticated = bearer !== undefined;

  return (
    <AuthContext.Provider value={{ bearer, authenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
