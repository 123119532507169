import React, { FC } from "react";

import { Stack, Toolbar } from "@mui/material";

import { Header } from "~components/Header";
import { useRefreshSignatureUrl } from "~hooks/useRefreshSignatureUrl";
import { Disagreement } from "~pages/esign/Disagreement";
import { StepProps } from "~pages/esign/index";
import { fullHeight } from "~utils/fullHeight";

export const Signature: FC<StepProps<"Signature">> = (props) => {
  useRefreshSignatureUrl(props);
  const { step, setStep, signatureSummary } = props;

  if (step.link === "disagreement") {
    return (
      <Disagreement
        step={step}
        setStep={setStep}
        signatureSummary={signatureSummary}
      />
    );
  } else {
    return (
      <Stack sx={fullHeight("height")}>
        <Header
          back={() => setStep({ value: "Recap", choice: step.choice })}
          title="Signature"
          progress={50}
        />
        <Toolbar />
        <iframe
          title="signature"
          src={step.link.url}
          style={{
            width: "100%",
            ...fullHeight("height"),
            border: 0,
            margin: "auto",
          }}
        />
      </Stack>
    );
  }
};
