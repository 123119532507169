import React, { FC } from "react";

import {
  Box,
  Collapse,
  FormControl,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { useMuiForm } from "~hooks/useMuiForm";
import { RepairMandateInfoSchema } from "~models/Schemas";
import { StepProps } from "~pages/esign";
import { RecapStep } from "~pages/esign/recap/index";

const RepairMandateInfoFC: FC<StepProps<"Recap">> = ({ signatureSummary }) => {
  const { register, watch } = useMuiForm();
  const repairMandate = signatureSummary.repairMandate;
  const coveredByInsurer = repairMandate?.coveredByInsurer;
  const chequeInformation = repairMandate?.chequeInformation;
  return (
    <Stack width="100%">
      <FormControl>
        <Typography fontWeight="bold" my={2}>
          Suivre les travaux par l’expert ?
        </Typography>
        <TextField
          label="Oui / Non"
          defaultValue=""
          select
          {...register("repairSupport")}
        >
          <MenuItem value="true">
            Oui,{" "}
            {coveredByInsurer
              ? "honoraires pris en charge par l'assureur"
              : "honoraires à ma charge"}
          </MenuItem>
          <MenuItem value="false">Non</MenuItem>
        </TextField>
      </FormControl>
      <Collapse in={watch("repairSupport") === "true"}>
        {!coveredByInsurer && (
          <Box>
            Je dois adresser un chèque à l'ordre de {}
            <b>{chequeInformation?.order}</b> d'un montant de
            <b>{chequeInformation?.amount}</b> à l'adresse ci-dessous.
            <Box textAlign="center">
              <Typography>{chequeInformation?.address1}</Typography>
              <Typography>{chequeInformation?.address2}</Typography>
              <Typography>{chequeInformation?.address3}</Typography>
              <Typography>{chequeInformation?.address4}</Typography>
            </Box>
          </Box>
        )}
        <FormControl fullWidth>
          <Typography fontWeight="bold" my={2}>
            Information sur votre garage
          </Typography>
          <TextField label="Nom du garage" {...register("garageName")} />
        </FormControl>
      </Collapse>
    </Stack>
  );
};

export const RepairMandateInfo: RecapStep = Object.assign(RepairMandateInfoFC, {
  title: "Informations complémentaires",
  schema: RepairMandateInfoSchema,
});
