import React, { FC } from "react";

import { Button, Stack, Typography } from "@mui/material";

import { ButtonGroup } from "~components/ButtonGroup";
import { CircledIcon } from "~components/CircledIcon";
import { Template } from "~components/Template";
import { useMediaDesktop } from "~hooks/useMediaDesktop";
import { Warning } from "~icons";
import { OtherChoiceStep } from "~models/StepStatus";
import { User } from "~models/User";
import { UserChoice } from "~models/UserChoice";
import { ChoiceCard } from "~pages/esign/choice/ChoiceCard";

interface Props {
  holder: User;
  choices: UserChoice[];
  onBack: () => void;
  onNext: (choice: UserChoice) => void;
}
export const OtherChoice: FC<Props> = ({ holder, onBack, onNext, choices }) => {
  const desktop = useMediaDesktop();
  const filteredChoices = choices.filter(
    (it) => it !== holder.signature?.choice
  );
  return (
    <Template
      title="Faire un autre choix"
      back={onBack}
      progress={20}
      maxWidth="xs"
      verticalCentered={desktop}
      stepStatus={OtherChoiceStep}
    >
      <CircledIcon fontSize="large">
        <Warning size="large" />
      </CircledIcon>
      <Typography>
        Si vous ne souhaitez pas céder le véhicule, votre dossier ne pourra pas
        être traité. L'expert en charge de votre dossier reviendra alors vers
        vous pour trouver une solution.
      </Typography>
      <Stack spacing={2}>
        {filteredChoices.map((choice) => (
          <ChoiceCard
            key={choice}
            choice={choice}
            onClick={() => onNext(choice)}
          />
        ))}
      </Stack>
      <ButtonGroup>
        <Button onClick={onBack} variant="outlined" fullWidth>
          Annuler
        </Button>
      </ButtonGroup>
    </Template>
  );
};
