import React, { FC, SVGProps } from "react";

import { formattedDate } from "~utils/date";

export type CGVariant =
  | "CrossOut"
  | "Write"
  | "Signed"
  | "CoSigned"
  | "Stamped";
type Props<T extends CGVariant> = (T extends "CrossOut"
  ? { variant: T }
  : { variant: T; signedDate: Date }) & {
  hasMultiSigners: boolean;
} & SVGProps<SVGSVGElement>;

const Rectangle: FC<{ color: string; d: string }> = ({ color, d }) => (
  <path
    fill="none"
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit="0"
    strokeWidth=".75"
    d={d}
  />
);

const color = "var(--mui-palette-primary-main)";
export const CG: <T extends CGVariant>(props: Props<T>) => JSX.Element = (
  props
) => {
  const {
    variant,
    signedDate: _ = null, // eslint-disable-line @typescript-eslint/no-unused-vars
    hasMultiSigners,
    ...rest
  } = { ...props };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.4 121.7" {...rest}>
      <image width="61" height="121" href="/static/img/screenshots/cg.png" />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="0"
        strokeWidth="2"
        d="M3.01 108.89 53.55 6"
      />
      {props.variant === "Write" && (
        <Rectangle color="red" d="M21.01 99.03h38.81v11.2H21.01z" />
      )}
      {props.variant !== "CrossOut" && (
        <text fill={color} fontSize="5" fontWeight="700">
          <tspan x="31" y="104">
            CEDE LE
          </tspan>
          <tspan x="25" y="109">
            {formattedDate(props.signedDate)}
          </tspan>
        </text>
      )}
      {props.variant === "Signed" && (
        <Rectangle color="green" d="M23.33 71.44h16.78v13.4H23.33z" />
      )}
      {["Signed", "CoSigned", "Stamped"].includes(props.variant) && (
        <path
          fill={color}
          d="M27.73 76.33a.5.5 0 0 1-.38.37c-.23-.02-.38-.15-.37-.38l.02-1.11A2.2 2.2 0 0 1 29.18 73c1.2.04 2.2 1.01 2.19 2.26.03.57-.02 1.12-.1 1.6l3.3-.87c.12-.06.29-.02.38.15.07.1.13.22.05.34l-1.17 2.43h3.08c.14-.02.33.17.37.37a.5.5 0 0 1-.38.36l-3.71-.06a.38.38 0 0 1-.29-.5l1.1-2.17-2.92.8-.11.43-.11.58c-.3.9-.69 1.86-1.19 2.66l-.62 1.09c-.22.5-.77.82-1.29.82a1.53 1.53 0 0 1-1.6-1.5l.08-2.18a1.9 1.9 0 0 1 1.4-1.76l2.92-.8.15-1.78-.03-.05c.03-.82-.63-1.5-1.46-1.51a1.5 1.5 0 0 0-1.47 1.51zm-.92 5.43c.13.9 1.14 1.2 1.64.42l.6-1.1c.44-.84.81-1.64 1.06-2.52l.16-.6-2.47.68c-.48.06-.82.47-.88 1.02a25.4 25.4 0 0 0-.1 2.1z"
        />
      )}
      {props.variant === "CoSigned" && (
        <Rectangle color="purple" d="M42.9 71.44h16.78v13.4H42.9z" />
      )}
      {["CoSigned", "Stamped"].includes(props.variant) && hasMultiSigners && (
        <path
          fill={color}
          d="M54.99 77.72a.84.84 0 0 0-.21-.31c-.2-.2-.48-.25-.79-.17a2.2 2.2 0 0 0-1 .61l-.33.32-.01-.3c-.03-.41-.27-.67-.67-.71-.45 0-.89.13-1.27.36l-.18.1-.06.02-.03.01-.08.03v-.1l.01-.04.03-.12.09-.38.13-.54.04-.07.13-.08.23-.14c.5-.25.93-.64 1.24-1.11.6-.9.79-1.6.6-2.12a.85.85 0 0 0-.45-.47 1.3 1.3 0 0 0-.61-.02c-.45.14-.87.58-1.1 1.14-.16.37-.48 1.54-.62 2.24l-.03.13-.36.16c-.73.33-1.5.54-2.29.63a.31.31 0 0 0-.13.1.3.3 0 0 0-.04.25c.02.1.07.18.15.21.05.03.15.04.28.03a7.68 7.68 0 0 0 1.78-.43l.33-.1h.03l.01.01.02.02v.01l.01.02v.02l-.01.04-.02.15-.1.45-.15.65-.25.19a9.9 9.9 0 0 0-2.14 2.12c-.34.51-.52 1.08-.73 1.65-.07.26-.07.32-.08.66v.35c.01.06.03.1.06.17.14.3.35.44.7.46.25.02.42-.02.67-.15.4-.19.81-.69 1.16-1.4.43-.96.77-1.96 1.01-3l.11-.4.05-.2c.01-.04.02-.05.04-.07a4.5 4.5 0 0 1 1.7-.81H52l-.02.26a4 4 0 0 1-.42 1.23c-.12.26-.18.43-.18.53 0 .06.01.1.04.14.03.04.08.08.13.11.1.08.21.07.34-.02l.08-.1.6-.67a5.3 5.3 0 0 1 1.33-1.31.98.98 0 0 1 .3-.11h.09l.07.07c.06.1.06.24.04.41-.01.18-.06.38-.13.58-.03.08-.04.2-.05.22 0 .16.16.3.3.3.1.01.17-.01.23-.07a.79.79 0 0 0 .15-.31c.18-.55.2-1 .08-1.33zm-2.72-4.51c.02.05.02.1.01.2 0 .22-.08.47-.23.79-.14.27-.45.74-.6.9-.15.16-.32.3-.5.4l-.05.02h-.02l-.02-.01-.02-.03v-.04c.04-.6.21-1.2.5-1.72.16-.3.4-.55.63-.62.05-.01.09-.03.13-.02.05 0 .07.03.11.06a.2.2 0 0 1 .06.06zm-3.02 6.14v.08l-.02.1-.07.26c-.2.79-.48 1.56-.83 2.3a3 3 0 0 1-.52.78.94.94 0 0 1-.55.3.47.47 0 0 1-.12.01.16.16 0 0 1-.1-.05c-.06-.06-.06-.14-.05-.28.06-.53.22-1.05.46-1.52.13-.31.34-.56.52-.82a9.71 9.71 0 0 1 1.18-1.16l.05-.02h.03z"
        />
      )}
      {"Stamped" === props.variant && (
        <g transform="rotate(-63.77 -54.42 -23.94)">
          <text
            x="-72.5"
            y="67.5"
            fill={color}
            fontSize="3.1"
            fontWeight="700"
            textAnchor="middle"
          >
            <tspan x="-72.5" y="67.5">
              VOTRE SOCIÉTÉ
            </tspan>
          </text>
          <text
            x="-72.7"
            y="70.4"
            fill={color}
            fontSize="1.6"
            fontWeight="700"
            textAnchor="middle"
          >
            <tspan x="-72.7" y="70.4">
              12 rue du paradis, 75009, Paris
            </tspan>
            <tspan x="-72.7" y="72.4">
              Tél : +33 (0)1 00 00 00 00
            </tspan>
          </text>
          <text
            x="-72.5"
            y="74.9"
            fill={color}
            fontSize="1"
            fontWeight="700"
            textAnchor="middle"
          >
            <tspan x="-72.5" y="74.9">
              SAS au capital de 5000€
            </tspan>
            <tspan x="-72.5" y="76.2">
              Siret : 000 000 000 - APE 000Z
            </tspan>
            <tspan x="-72.5" y="77.4">
              N° CEE FR 00 000 000 000
            </tspan>
          </text>
          <rect
            width="31.4"
            height="15.8"
            x="-88.3"
            y="63.2"
            fill="none"
            stroke={color}
            strokeWidth=".2"
            rx=".8"
          />
          <rect
            width="33"
            height="17.3"
            x="-89.1"
            y="62.5"
            fill="none"
            stroke={color}
            strokeWidth=".5"
            rx=".7"
          />
        </g>
      )}
    </svg>
  );
};
