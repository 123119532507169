import React from "react";

import { createBrowserRouter } from "react-router-dom";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size
// Guards
import { AuthGuard } from "~components/guards/AuthGuard";
import { ComfirmSent } from "~pages/ComfirmSent";
import { Fallback } from "~pages/Fallback";
import { SignIn } from "~pages/auth/SignIn";
import { Verification } from "~pages/auth/Verification";
import { Esign } from "~pages/esign";
import { Posthoc } from "~pages/posthoc";
import { esignService } from "~services/esignService";

import { AuthLayout } from "./layouts/AuthLayout";
import { EsignLayout } from "./layouts/EsignLayout";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthGuard>
        <EsignLayout />
      </AuthGuard>
    ),
    loader: esignService.getSignatureSummary,
    errorElement: <Fallback />,
    children: [
      {
        path: "",
        Component: Esign,
      },
      {
        path: "verify",
        Component: Verification,
      },
      {
        path: "posthoc",
        Component: Posthoc,
      },
    ],
  },
  {
    path: ":loginUrl",
    element: (
      <AuthLayout>
        <SignIn />
      </AuthLayout>
    ),
    loader: ({ params }) => esignService.getLoginInfo(params.loginUrl!),
    errorElement: <Fallback />,
  },
  {
    path: ":loginUrl/confirm",
    Component: ComfirmSent,
    loader: ({ params }) => esignService.validate(params.loginUrl!),
    errorElement: <Fallback />,
  },
]);
