import React, { FC } from "react";

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from "@mui/material";

import logotypes from "@avto-dev/vehicle-logotypes/src/vehicle-logotypes.json";
import { UserLoginInfo } from "~models/UserLoginInfo";
import insuranceLogos from "~utils/insuranceLogos";

interface Props {
  info: UserLoginInfo;
}

export const LoginCard: FC<Props> = ({ info }) => {
  // @ts-ignore todo remove ts-ignore?
  const carLogo = logotypes?.[info.vehicleBrand.toLowerCase()]?.logotype.uri;
  const insuranceLogo = insuranceLogos[info.insurance];
  return (
    <Card>
      <CardHeader title="Véhicule concerné" />
      <CardContent sx={{ backgroundColor: "primary.light" }}>
        <Stack alignItems="center">
          <Stack direction="row" alignItems="center">
            <img
              src={carLogo}
              alt={`${info.vehicleBrand} logo`}
              style={{ height: "4em" }}
            />
            <Stack textAlign="left" spacing={0}>
              <Typography fontWeight="bold">
                {info.vehicleBrand} {info.vehicleName}
              </Typography>
              <Typography>{info.matriculation}</Typography>
            </Stack>
          </Stack>
          <Divider component="div" flexItem />
          <Stack direction="row" alignItems="center">
            <Typography>Assureur</Typography>
            {insuranceLogo ? (
              <img
                src={insuranceLogo}
                alt={info.insurance}
                style={{ height: "4em" }}
              />
            ) : (
              <Typography>{info.insurance}</Typography>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
