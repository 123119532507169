import React, { FC } from "react";

import { EastRounded } from "@mui/icons-material";
import {
  Card,
  CardActionArea,
  CardContent,
  Icon,
  Stack,
  Typography,
} from "@mui/material";

import { CircledIcon } from "~components/CircledIcon";
import { SinisterType } from "~models/SinisterType";
import { UserChoice } from "~models/UserChoice";
import { ChoiceIcon, ChoiceTitle } from "~utils/wording";

interface Props {
  choice: UserChoice;
  onClick: () => void;
  sinisterType?: SinisterType;
}
export const ChoiceCard: FC<Props> = ({ choice, onClick, sinisterType }) => (
  <Card>
    <CardActionArea onClick={onClick}>
      <CardContent>
        <Stack direction="row" alignItems="center" textAlign="left">
          <CircledIcon>{ChoiceIcon[choice]({})}</CircledIcon>
          <Typography flex="1" fontWeight="bold">
            {ChoiceTitle(choice, sinisterType)}
          </Typography>
          <Icon>
            <EastRounded color="primary" />
          </Icon>
        </Stack>
      </CardContent>
    </CardActionArea>
  </Card>
);
