import React, { FC } from "react";

import {
  Card,
  CardContent,
  CardHeader,
  CardHeaderProps,
  Stack,
  Typography,
} from "@mui/material";

import { ButtonGroup } from "~components/ButtonGroup";
import { CircledIcon } from "~components/CircledIcon";
import { NextButton } from "~components/NextButton";
import { Template } from "~components/Template";
import { useMediaDesktop } from "~hooks/useMediaDesktop";
import { CG, CarKeys } from "~icons";
import { SendCGSVG } from "~illustrations";
import { SendInstructionStep } from "~models/StepStatus";
import { PosthocStep } from "~pages/esign/posthoc";

const nonEmptyAddressInfo = <T,>(info?: T) => (info ? `${info} ` : "");

const Info: FC<Pick<CardHeaderProps, "avatar" | "title" | "subheader">> = ({
  avatar,
  title,
  subheader,
}) => (
  <Card>
    <CardHeader
      sx={{ textAlign: "left" }}
      avatar={<CircledIcon>{avatar}</CircledIcon>}
      title={title}
      subheader={subheader}
    />
  </Card>
);
export const SendCG: PosthocStep = ({ back, next, signatureSummary }) => {
  const desktop = useMediaDesktop();
  const depository = signatureSummary.belongingsDepository;

  const keyConsign = () => {
    switch (signatureSummary.applicationData.sinisterType) {
      case "VNR":
        return {
          title: `L'ensemble des clés en ma possession`,
          subHeader: `Le nombre de clés retournées doit correspondre à votre déclaration à l'assurance`,
        };
      default:
        return {
          title: `Le double des clés (si disponible)`,
          subHeader: `La clé principale doit toujours rester avec le véhicule`,
        };
    }
  };

  return (
    <Template
      back={back}
      title="Consigne Courrier"
      verticalCentered={desktop}
      progress={90}
      stepStatus={SendInstructionStep}
    >
      <SendCGSVG />
      <Stack spacing={0} mt={0}>
        <Typography textAlign="center">
          J’envoie par courier recommandé,
        </Typography>
        <Typography component="b" fontWeight="bold" color="red">
          sous 3 jours
          <Typography component="b" fontWeight="bold" color="black">
            {" "}
            et dans une enveloppe résistante :
          </Typography>
        </Typography>
      </Stack>
      <Stack>
        <Stack alignItems="center" spacing={2}>
          <Info
            avatar={<CG />}
            title="Le certificat d’immatriculation (carte grise)"
            subheader="daté, barré et signé"
          />
          <Typography fontWeight="bold">+</Typography>
          <Info
            avatar={<CarKeys />}
            title={keyConsign().title}
            subheader={keyConsign().subHeader}
          />
        </Stack>
        <Card>
          <CardContent sx={{ textAlign: "center" }}>
            <Typography>A l'adresse ci-dessous :</Typography>
            <Stack spacing={0} mt={4}>
              <Typography fontWeight="bold" textAlign="center">
                {depository.depositoryName}
              </Typography>
              <Typography fontWeight="bold">
                {nonEmptyAddressInfo(depository.depositoryAddressNum)}
                {nonEmptyAddressInfo(depository.depositoryAddressRep)}
                {depository.depositoryAddressName}{" "}
                {depository.depositoryAddressComplement}
              </Typography>
              <Typography fontWeight="bold">
                {depository.depositoryAddressPostcode}{" "}
                {depository.depositoryAddressCityName}
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      </Stack>

      <ButtonGroup>
        <NextButton onClick={next}>Valider</NextButton>
      </ButtonGroup>
    </Template>
  );
};
