import React from "react";

import { EastRounded } from "@mui/icons-material";
import { Box, Button, buttonClasses } from "@mui/material";
import { ButtonTypeMap } from "@mui/material/Button/Button";
import { ExtendButtonBase } from "@mui/material/ButtonBase";

export const NextButton: ExtendButtonBase<ButtonTypeMap> = (props: any) => (
  <Button
    variant="contained"
    fullWidth
    {...props}
    children={<Box className="children">{props.children}</Box>}
    endIcon={<EastRounded />}
    sx={{
      textAlign: "center",
      display: "grid",
      gridTemplateColumns: "1.25em 1fr 1.25em",
      ".children": {
        gridColumnStart: 2,
        padding: (theme) => theme.spacing(0, 4, 0, 2),
      },
      [`.${buttonClasses.endIcon}`]: {
        marginLeft: "auto",
      },
    }}
  />
);
