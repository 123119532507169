interface SectionType {
  count: number;
}

export const removeSeparator = (matriculation: string): string => {
  if (matriculation) return matriculation.replace(/[- ]/g, "");
  return "";
};

export const getMatriculationMask = (matriculation: string) => {
  if (/-[A-Z]{2}$/.test(matriculation)) return "aa-000-aa";
  else if (/\d{2}$/.test(matriculation)) return "0[000] a[aa] 00";
  else return "a[a] 0[00] a";
};

// process matriculation to check type of section part in all inputs
export const processMatriculation = (
  matriculation: string,
  isDashSeparator: boolean
): { firstSection: SectionType; secondSection: SectionType } => {
  const separator = isDashSeparator ? "-" : " ";
  const inputMask = matriculation.split(separator);

  const firstSection: SectionType = {
    count: inputMask[0].length,
  };

  const secondSection: SectionType = {
    count: inputMask[1].length,
  };

  return { firstSection, secondSection };
};
