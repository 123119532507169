import {
  FieldValues,
  UseFormRegister,
  useForm,
  useFormContext,
} from "react-hook-form";
import { UseFormProps } from "react-hook-form/dist/types";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { FieldPath } from "react-hook-form/dist/types/path";
import { RegisterOptions } from "react-hook-form/dist/types/validator";

function resolve(obj: any, path: FieldPath<FieldValues>): any {
  return path.split(".").reduce((prev, curr) => prev?.[curr], obj);
}

const withPrefix = (prefix: string, name: string) =>
  prefix !== "" ? [prefix, name].join(".") : name;

export function muiRegister(
  prefix: string,
  register: UseFormRegister<FieldValues>,
  errors: FieldErrors
) {
  return (name: FieldPath<FieldValues>, options?: RegisterOptions) => {
    const path = withPrefix(prefix, name);
    const { ref, ...rest } = register(path, options);
    const error = resolve(errors, path);

    return {
      inputRef: ref,
      helperText: error && `${error?.message}`,
      error: Boolean(error),
      ...rest,
    };
  };
}

export const useMuiForm = (prefix = "") => {
  const {
    register,
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = useFormContext();
  return {
    register: muiRegister(prefix, register, errors),
    watch: (name: string) => watch(withPrefix(prefix, name)),
    getValues,
    setValue,
  };
};

export function useMuiRootForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any
>(props?: UseFormProps<TFieldValues, TContext>) {
  const {
    register,
    formState: {
      isDirty,
      isLoading,
      isSubmitted,
      isSubmitSuccessful,
      isSubmitting,
      isValidating,
      isValid,
      submitCount,
      defaultValues,
      dirtyFields,
      touchedFields,
      errors,
    },
    ...rest
  } = useForm<TFieldValues, TContext>(props);
  return {
    // @ts-ignore
    muiRegister: muiRegister("", register, errors),
    register,
    formState: {
      isDirty,
      isLoading,
      isSubmitted,
      isSubmitSuccessful,
      isSubmitting,
      isValidating,
      isValid,
      submitCount,
      defaultValues,
      dirtyFields,
      touchedFields,
      errors,
    },
    ...rest,
  };
}
