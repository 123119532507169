import React, { FC } from "react";

import { SvgIcon } from "@mui/material";

import { ColorIconProps, Path } from "~icons/index";

export const CarKeys: FC<ColorIconProps> = ({
  primaryColor,
  secondaryColor = "primary.dark",
  size,
}) => (
  <SvgIcon inheritViewBox fontSize={size}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 407">
      <Path
        color={primaryColor}
        d="M177 12a113 113 0 0 0-97 55l-7 15-20 50-12 28a64 64 0 0 0-41 60v48a63 63 0 0 0 16 42v54a32 32 0 0 0 32 32h32c18 0 32-15 32-32v-32h221V221a129 129 0 0 1-53-65H112l20-50c7-18 25-30 44-30h102a127 127 0 0 1 41-63l-15-1zM80 204c19 0 48 28 48 48 0 19-29 15-48 15s-32-12-32-31c0-20 13-32 32-32z"
      />
      <Path
        color={secondaryColor}
        d="M479 191a112 112 0 1 0-130 20v145c0 4 2 8 4 11l36 36c6 6 16 6 22 0l36-36c6-6 6-16 0-22l-18-18 18-18c6-6 6-15 0-21l-18-18 18-18c3-3 4-7 4-11v-30c10-5 20-12 28-20zM382 58a25 25 0 1 1 36 36 25 25 0 1 1-36-36z"
      />
    </svg>
  </SvgIcon>
);
