import { SignatureSummary } from "~models/SignatureSummary";

export const showCGInstruction = (signatureSummary: SignatureSummary) => {
  const signatureData = signatureSummary.signatureData;
  const user = signatureData.currentUser;

  const choice = user.signature?.choice;
  const hasCG = !user.isRenter || user.hasCG;
  return Boolean(choice === "sell" && hasCG);
};

export const userSigned = (signatureSummary: SignatureSummary) =>
  signatureSummary.signatureData.currentUser.activeWorkflowStatus?.status ===
  "done";
