export enum CheckerErrorType {
  DATA = "administrative",
  CONSIGNE = "instructions",
}

export type CheckerError = {
  errorType: CheckerErrorType;
  details: string;
};

export interface CheckerResponse {
  totalTries: number;
  currentTryErrors: Array<CheckerError>;
}
