function createImageCanvas(
  image: FileWithData,
  ratio: number
): Promise<HTMLCanvasElement> {
  return new Promise(function (resolve) {
    const img = new Image();

    img.onload = function () {
      const canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d")!;
      canvas.width = img.width * ratio;
      canvas.height = img.height * ratio;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      resolve(canvas);
    };
    img.src = image.data;
  });
}

function compressImage(
  canvas: HTMLCanvasElement,
  type: string,
  quality: number
): Promise<FileWithData> {
  return new Promise(function (resolve) {
    const data = canvas.toDataURL(type, quality);
    canvas.toBlob((file) => resolve({ file: file!, data }), type, quality);
  });
}

export async function resizeImage(
  image: FileWithData,
  maxSize: number
): Promise<FileWithData> {
  if (image.file.size < maxSize) {
    return Promise.resolve(image);
  } else {
    const canvas = await createImageCanvas(image, 0.7);
    const res = await compressImage(canvas, image.file.type, 0.9);
    return await resizeImage(res, maxSize);
  }
}

export function resizeInputImage(file: File) {
  const data = URL.createObjectURL(file);
  return resizeImage({ file, data }, 1900000);
}

export type FileWithData = { file: Blob; data: string };
