import React, { FC } from "react";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Typography,
} from "@mui/material";

import { BottomSheet } from "~components/BottomSheet";
import { ButtonGroup } from "~components/ButtonGroup";
import { NextButton } from "~components/NextButton";
import { Template } from "~components/Template";
import { CarCrash } from "~components/icons/CarCrash";
import { CarRepair } from "~components/icons/CarRepair";
import { useToggle } from "~hooks/useToggle";
import { CarValue } from "~icons";
import { SinisterType } from "~models/SinisterType";
import { ConclusionStep } from "~models/StepStatus";
import { StepProps } from "~pages/esign";

import {
  EstimateRepairInfo,
  EstimateValueInfo,
  RecommendationTexts,
  ResidualValueInfo,
} from "./RecommendationTexts";

const Amount: FC<Props["amount"]> = ({ value, label }) => {
  const [integer, decimal = "00"] = value.split(",");
  return (
    <Stack
      direction="row"
      spacing={0}
      justifyContent="center"
      alignItems="baseline"
    >
      <Typography fontSize="1.5em" fontWeight="bold">
        {integer}
      </Typography>
      <Typography fontWeight="bold">,{decimal}</Typography>
      <Typography mx={2} fontSize="1.5em" fontWeight="bold">
        €
      </Typography>
      <Typography fontWeight="bold">{label}</Typography>
    </Stack>
  );
};

interface Props {
  title: string;
  recoverableVAT?: boolean;
  amount: {
    value: string;
    label: string;
  };
  icon: React.ReactNode;
  color: string;
  info: React.ReactNode;
}

const ConclusionCard: FC<Props> = ({ title, amount, icon, color, info }) => {
  const [showInfo, toggleInfo] = useToggle();
  return (
    <Card>
      <CardHeader
        title={title}
        avatar={icon}
        sx={{
          textAlign: "center",
          backgroundColor: `${color}.color`,
          color: `${color}.contrastText`,
        }}
      />
      <CardContent sx={{ textAlign: "center" }}>
        <Amount {...amount} />
        <Link color="primary.dark" onClick={toggleInfo}>
          En savoir plus
        </Link>
      </CardContent>
      <BottomSheet open={showInfo} onClose={toggleInfo}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent sx={{ textAlign: "justify" }}>{info}</DialogContent>
        <DialogActions>
          <Button onClick={toggleInfo} variant="contained">
            Ok
          </Button>
        </DialogActions>
      </BottomSheet>
    </Card>
  );
};

export const Conclusion: FC<StepProps<"Conclusion">> = ({
  setStep,
  signatureSummary,
}) => {
  const data = signatureSummary.applicationData;
  const label = data.recoverableVAT ? "HT" : "TTC";

  const estimatedRepairCost = data[`estimatedRepairCost${label}`];
  const replaceValue = data[`replaceValue${label}`];
  const residualValue = data[`residualValue${label}`];

  return (
    <Template
      progress={10}
      title="Synthèse de votre dossier"
      verticalCentered
      stepStatus={ConclusionStep}
    >
      {data.sinisterType.includes("CONSERVATOIRE") && (
        <ConclusionCard
          icon={<CarCrash secondaryColor="inherit" />}
          color="conclusion.totalCarValue"
          title="Valeur après sinistre de votre véhicule"
          amount={{
            value: residualValue,
            label,
          }}
          info={ResidualValueInfo}
        />
      )}

      <Stack spacing={2} direction={{ xs: "column", sm: "row" }} width="100%">
        {estimatedRepairCost && (
          <ConclusionCard
            icon={<CarRepair secondaryColor="inherit" />}
            color="conclusion.repairAmount"
            title="Montant des réparations"
            amount={{
              value: estimatedRepairCost,
              label,
            }}
            info={EstimateRepairInfo}
          />
        )}
        <ConclusionCard
          icon={<CarValue secondaryColor="inherit" />}
          color="conclusion.carValue"
          title="Valeur de votre véhicule"
          amount={{
            value: replaceValue,
            label,
          }}
          info={EstimateValueInfo(data.sinisterType as SinisterType)}
        />
      </Stack>
      <Typography>
        {RecommendationTexts(data.insurance)[data.sinisterType]}
      </Typography>
      <ButtonGroup>
        <NextButton onClick={() => setStep({ value: "Choice" })}>
          Suivant
        </NextButton>
      </ButtonGroup>
    </Template>
  );
};
